import React from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';
import css from 'src/theme/css';

import * as S from 'src/components/Form/style';
import FormField from 'src/components/FormField';
import SubmitButton from 'src/components/SubmitButton';

import formData from './data';

export default ({submit = null, disabled = false, isHidden = false}) => {
    const { register, errors, handleSubmit } = useForm();
    
    return (
        <form 
            name={formData.slug}
            id={formData.id}
            method="post"
            data-netlify={true}
            onSubmit={handleSubmit(submit)}
            hidden={isHidden}
        >
            <input type="hidden" name="form-name" value={formData.slug} />

            {/* Include empty email input for Netlify to pickup, send real value when submitting */}
            <input type="hidden" name="email" />

            <S.Form
                css={css({
                    gridTemplateColumns: [
                        '1fr',
                        null,
                        'repeat(2, minmax(auto, 24rem))'
                    ],
                })}
            >
                {formData.formFields2Cols.map(({ name, ...other }) => (
                    <FormField
                        {...other}
                        labelCss={{
                            fontSize: [null, null, '1.02rem']
                        }}
                        key={name}
                        name={name}
                        error={get(errors, [name, 'message'])}
                        register={register}
                        disabled={disabled}
                        variant={'c2c'}
                    />
                ))}
            </S.Form>
            
            <S.Form
                css={css({
                    gridTemplateColumns: '1fr',
                })}
            >
                {formData.formFields1Col.map(({ name, ...other }) => (
                    <FormField
                        {...other}
                        labelCss={{
                            fontSize: [null, null, '1.02rem'],
                            whiteSpace: ['initial', 'nowrap', null]
                        }}
                        key={name}
                        name={name}
                        error={get(errors, [name, 'message'])}
                        register={register}
                        disabled={disabled}
                        variant={'c2c'}
                    />
                ))}
            </S.Form>

            <SubmitButton
                state={disabled ? 'success' : 'initial'}
                label={disabled ? 'Submitted' : formData.submitButtonText}
                disabled={disabled}
                css={css({
                    cursor: disabled ? 'auto' : 'pointer',
                    width: '100%',
                    mt: 40,
                    backgroundColor: 'white',
                    color: 'black'
                    // ...(buttonCss || {}),
                })}
            />
        </form>
    )
}