import React, { forwardRef } from 'react';
import css from 'src/theme/css';
import get from 'lodash/get';
import { Box } from 'src/components/FlexBox';

const paragraphMaxWidthLookup = {
  s: '20rem',
  m: '55rem',
  l: '60rem',
};

const headingMaxWidthLookup = {
  s: '40rem',
  m: '60rem',
  l: 'none',
};

export default forwardRef(({ size, collapseMargin, ...other }, ref) => {
  return (
    <Box
      {...other}
      ref={ref}
      flexDirection="column"
      paddingTop={
        collapseMargin &&
        (collapseMargin === 'Top' || collapseMargin === 'Both')
          ? '0 !important'
          : ''
      }
      paddingBottom={
        collapseMargin &&
        (collapseMargin === 'Bottom' || collapseMargin === 'Both')
          ? '0 !important'
          : ''
      }
      css={css({
        '> *': {
          margin: 0,
        },
        '> * + *': {
          marginTop: 4,
        },
        '> p': {
          maxWidth: get(paragraphMaxWidthLookup, size),
        },
        '> h1, > h2, > h3, > h4, > h5, > h6': {
          maxWidth: get(headingMaxWidthLookup, size),
        },
        '> h1 + div, > h2 + div, > h3 + div': {
          marginTop: 4,
        },
        '> p + p': {
          marginTop: 3,
        },
        '> h6 + p': {
          marginTop: 3,
        },
        '> p + h4': {
          marginTop: 1,
        },
        '> img': {
          maxWidth: '100%',
          borderRadius: 1,
        },
        '> * + img, > img + *': {
          marginTop: 5,
        },
        '> blockquote, > blockquote + *': {
          marginTop: 5,
        },
        li: {
          color: 'modeAlpha.7',
        },
      })}
    />
  );
});
