import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { paramCase } from 'change-case';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import every from 'lodash/every';
import cond from 'lodash/cond';
import css from 'src/theme/css';
import { isObject, isArrayLike, isDefined, isString } from 'typical';

import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import { Flex } from 'src/components/FlexBox';

import Accordion from 'src/components/Accordion';
import Form from 'src/components/Form';
import AnnouncementBanner from 'src/components/AnnouncementBanner';
import SmartButton from 'src/components/SmartButton';
import Link from 'src/components/Link';
import Table from 'src/components/Table';
import Quote from 'src/components/Quote';
import EmailInput from 'src/components/EmailInput';
import IconPreset from 'src/components/IconPreset';
import ImageGroup from 'src/components/ImageGroup';

const ContentGroup = ({ contentNodes }) => (
  <Flex gx={4} gy={3} flexWrap="wrap">
    {contentNodes.map((button) => (
      <div
        css={css({
          display: 'flex',
          justifyContent: ['center', null, 'flex-start'],
          width: ['100%', 'auto', '100%', 'auto'],
        })}
      >
        <SmartButton
          key={button.id}
          css={css({
            flexShrink: '0',
          })}
          {...button}
        />
      </div>
    ))}
  </Flex>
);

const locale = 'en-US';

const isEntry = (data) =>
  isObject(data) &&
  isObject(data.fields) &&
  isObject(data.sys) &&
  isString(data.sys.id);

const getProps = cond([
  [
    isEntry,
    (data) => ({
      ...mapValues(data.fields, (v) => getProps(v[locale])),
      id: data.sys.id,
    }),
  ],
  [isArrayLike, (data) => data.map(getProps)],
  [() => true, (data) => data],
]);

const getComponent = (id) => {
  switch (id) {
    case 'accordion':
      return Accordion;
    case 'airtableForm':
      return Form;
    case 'announcementBanner':
      return AnnouncementBanner;
    case 'button':
      return SmartButton;
    case 'table':
      return Table;
    case 'quote':
      return Quote;
    case 'emailInput':
      return EmailInput;
    case 'brand':
      return IconPreset;
    case 'imageGroup':
      return ImageGroup;
    case 'contentGroup':
      return ContentGroup;
    default:
      return undefined;
  }
};

const isEmpty = (node) => {
  return every(
    node.content,
    (child) => isString(child.value) && child.value.trim() === ''
  );
};

const createAnchor = (prefix, children) => {
  return isArrayLike(children)
    ? `${prefix}-${paramCase(children.join(''))}`
    : false;
};

const RichText = ({ json, ...other }) => {
  const getEmbeddedEntry = (node) => {
    const props = getProps(get(node, 'data.target'));
    const Component = getComponent(
      get(node, 'data.target.sys.contentType.sys.id')
    );

    if (isObject(props) && isDefined(Component)) {
      return <Component {...props} />;
    }

    return null;
  };

  const getEmbeddedAsset = (node) => {
    const src = get(node, 'data.target.fields.file["en-US"].url');
    const alt = get(node, 'data.target.fields.description["en-US"]');

    if (isString(src)) {
      return <img src={src} alt={alt} />;
    }

    return null;
  };

  const options = {
    renderText: (text) =>
      text.split('\n').map((t, i) =>
        i > 0 ? (
          <React.Fragment key={`${i}-${t.slice(0, 5)}`}>
            <br />
            {t}
          </React.Fragment>
        ) : (
          t
        )
      ),
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <Link url={data.uri}>{children}</Link>
      ),
      [BLOCKS.HEADING_1]: (_, children) => (
        <Text variant="heading.xxxl" as="h1" id={createAnchor('h1', children)}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <Text variant="heading.xxl" as="h2" id={createAnchor('h2', children)}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <Text variant="heading.xl" as="h3" id={createAnchor('h3', children)}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <Text variant="heading.l" as="h4" id={createAnchor('h4', children)}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_5]: (_, children) => (
        <Text variant="heading.m" as="h5" id={createAnchor('h5', children)}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_6]: (_, children) => (
        <Text variant="heading.s" as="h6" id={createAnchor('h6', children)}>
          {children}
        </Text>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) =>
        isEmpty(node) ? null : <Text as="p">{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: getEmbeddedAsset,
      [BLOCKS.EMBEDDED_ENTRY]: getEmbeddedEntry,
    },
  };

  return (
    <TextStack {...other}>{documentToReactComponents(json, options)}</TextStack>
  );
};

export default RichText;

export { alignments } from 'src/components/Stack';
