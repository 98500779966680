import { isDefined } from 'typical';

import urls, { useHasAdAttribution } from 'src/utils/urls';
import { useExperimentValue } from 'src/utils/experiments';

export default () => {
  const trialFlowUrl = useExperimentValue('trial_flow_url');
  const hasAdAttribution = useHasAdAttribution();

  return isDefined(trialFlowUrl)
    ? trialFlowUrl
    : hasAdAttribution
    ? urls.signUpFromAd
    : urls.signUp;
};
