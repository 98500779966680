import { useStore } from '../components/GlobalState';

/** 
 * imagesLoaded and imagesLoading are both arrays of filenmaes. This uses
 * arrays rather than objects for faster comparison.
*/
export default () => {
    const imagesLoading = useStore((state) => state.imagesLoading);
    const imagesLoaded = useStore((state) => state.imagesLoaded);
    const allImagesLoaded = useStore((state) => state.allImagesLoaded);
    const setImagesLoaded = useStore((state) => state.setImagesLoaded);
    const setImagesLoading = useStore((state) => state.setImagesLoading);

    const onStartLoad = (imgId, wasCached) => {
        if (!imgId) return;
        setImagesLoading(imgId);
        // onLoad won't fire for cached images
        if (wasCached)
        setImagesLoaded(imgId);
    }

    const onLoad = (imgId) => {
        if (!imgId) return;
        setImagesLoaded(imgId);
    }
    return {
        onStartLoad,
        onLoad,
        imagesLoaded,
        imagesLoading,
        allImagesLoaded
    }
};
