import React from 'react';
import { useStore } from 'src/components/GlobalState';
import TextLink from 'src/components/Link';
import Button from 'src/components/Button';
import urls from 'src/utils/urls';
import isWindowDefined from 'src/utils/isWindowDefined';
import useSignUpUrl from 'src/hooks/useSignUpUrl';

export const Link = (props) => (
  <TextLink
    css={{
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    {...props}
  />
);

export const AppStore = (props) => (
  <Link
    {...props}
    url="https://itunes.apple.com/us/app/frame.io-video-review-collaboration/id1056295002?ls=1&mt=8"
    aria-label="Download on the App Store"
  />
);

export const CalendlyModal = ({ onClick, ...other }) => {
  const openModal = useStore((state) => state.openModal);
  return (
    <Button
      {...other}
      onClick={(event) => {
        onClick(event);
        openModal('calendly');
      }}
    />
  );
};

CalendlyModal.defaultProps = {
  onClick: () => {},
};

export const Download = ({
  media: {
    file: { url },
  },
  ...other
}) => {
  return <Link {...other} download url={url} />;
};

export const LogIn = (props) => {
  return <Link {...props} url={urls.logIn} />;
};

export const SignUp = (props) => {
  const url = useSignUpUrl();

  return <Link {...props} url={url} />;
};

export const GoToApp = (props) => {
  return <Link {...props} url={urls.goToApp} />;
};

export const TwitterFollow = (props) => (
  <Link {...props} url="https://twitter.com/Frame_io" />
);

export const TwitterShare = ({ text, ...other }) => {
  const sourceUrl = isWindowDefined ? window.location : 'https://frame.io';
  const url = `https://twitter.com/intent/tweet?url=${sourceUrl}&text=${
    text || ''
  }`;
  return <Link {...other} url={url} />;
};

export const VideoModal = ({ onClick, videoUrl, ...other }) => {
  const openModal = useStore((state) => state.openModal);
  return (
    <Button
      {...other}
      onClick={(event) => {
        onClick(event);
        openModal('video', videoUrl);
      }}
    />
  );
};

VideoModal.defaultProps = {
  onClick: () => {},
};
