import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import css from 'src/theme/css';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { useStore } from 'src/components/GlobalState';
import Link from 'src/components/Link';
import { Flex, Grid } from 'src/components/FlexBox';
import Text from 'src/components/Text';
import SmartButton, * as smartButton from 'src/components/SmartButton';

export default () => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const {
    contentfulFooter: { linkLists },
    contentfulNav: { signInLink, signUpLink, goToAppLink },
  } = useStaticQuery(graphql`
    query {
      contentfulNav(slug: { eq: "default-nav" }) {
        goToAppLink {
          label
          url
          trackingID
        }
        signUpLink {
          label
          url
          trackingID
        }
        signInLink {
          label
          url
          trackingID
        }
      }
      contentfulFooter(slug: { eq: "default-footer" }) {
        linkLists {
          id
          title
          contentNodes {
            ... on ContentfulLink {
              id
              label
              url
              trackingID
            }
          }
        }
      }
    }
  `);

  return (
    <ModuleWrapper colorMode="dark" css={css({ py: 5, px: 'pageMargin' })}>
      <Flex flexDirection="column" gy={5}>
        <Flex gy={5} gx={5} flexDirection={['column', null, 'row']}>
          <Grid
            flexGrow={1}
            gridGap={4}
            gridTemplateColumns={['1fr 1fr', '1fr 1fr 1fr 1fr']}
          >
            {linkLists.map(({ id, title, contentNodes }) => (
              <Flex gy={2} key={id} flexDirection="column">
                <Text variant="heading.xs" css={css({ pb: 2 })}>
                  {title}
                </Text>
                <hr
                  css={css({ height: '1px', bg: 'gray600', border: 'none' })}
                ></hr>
                {contentNodes.map(({ id, label, ...link }) => (
                  <Link
                    key={id}
                    {...link}
                    css={css({ textDecoration: 'none' })}
                  >
                    <Text variant="small" color="gray400">
                      {label}
                    </Text>
                  </Link>
                ))}
              </Flex>
            ))}
          </Grid>
          <Flex gx={4} gy={3} flexShrink={0} justifyContent="center">
            {isLoggedIn ? (
              <SmartButton
                variant={smartButton.variants.primary}
                action={smartButton.actions.goToApp}
                label={goToAppLink.label}
                url={goToAppLink.url}
                css={`
                  margin-top: 1rem;
                `}
              />
            ) : (
              <>
                <SmartButton
                  action={smartButton.actions.signIn}
                  variant="Caps"
                  label={signInLink.label}
                  url={signInLink.url}
                />
                <SmartButton
                  variant="Primary"
                  action={smartButton.actions.signUp}
                  variant={smartButton.variants.primary}
                  label={signUpLink.label}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </ModuleWrapper>
  );
};
