import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'src/theme';

export default ({ mode, children }) => {
  const modifiedTheme = useMemo(() => {
    const colors = {
      ...theme.colors,
      mode: mode === modes.dark ? theme.colors.white : theme.colors.black,
      antiMode: mode === modes.dark ? theme.colors.black : theme.colors.white,
      modeAlpha:
        mode === modes.dark ? theme.colors.whiteAlpha : theme.colors.blackAlpha,
      antiModeAlpha:
        mode === modes.dark ? theme.colors.blackAlpha : theme.colors.whiteAlpha,
    };

    return {
      ...theme,
      mode,
      isDark: mode === modes.dark,
      isLight: mode === modes.light,
      colors,
      backgrounds: { ...colors, ...theme.backgrounds },
    };
  }, [mode]);

  return <ThemeProvider theme={modifiedTheme}>{children}</ThemeProvider>;
};

export const modes = {
  light: 'light',
  dark: 'dark',
};
