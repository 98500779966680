import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ className }) => (
  <svg className={className} viewBox="0 0 20 18">
    <path
      fillRule="evenodd"
      d="M14.786 9.983H2.176c-.726 0-1.314-.58-1.314-1.305 0-.72.587-1.305 1.314-1.305h12.499L9.522 2.221A1.299 1.299 0 0 1 9.527.38a1.3 1.3 0 0 1 1.84-.004l7.39 7.39c.268.267.395.621.38.971.013.348-.113.699-.38.965l-7.39 7.39a1.299 1.299 0 0 1-1.84-.004 1.3 1.3 0 0 1-.005-1.841l5.264-5.264z"
    />
  </svg>
);

ArrowRight.propTypes = {
  className: PropTypes.string,
};

export default ArrowRight;
