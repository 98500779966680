import React, { forwardRef } from 'react';
import { useStore } from 'src/components/GlobalState';
import { useStaticQuery, graphql } from 'gatsby';
import Form from 'src/components/Form';
import TextStack from 'src/components/TextStack';
import Text from 'src/components/Text';
import { Flex } from 'src/components/FlexBox';
import { useResponsiveValue } from 'src/components/Breakpoints';

export default forwardRef((_, ref) => {
  const closeModal = useStore((state) => state.closeModal);
  const columnCount = useResponsiveValue([1]);
  const { form } = useStaticQuery(graphql`
    query {
      form: contentfulForm(slug: { eq: "global#blur-gate-form" }) {
        ...FormFragment
      }
    }
  `);

  return (
    <Flex
      ref={ref}
      flexDirection="column"
      gy={5}
      alignItems="center"
      justifyContent="center"
    >
      <TextStack textAlign="center">
        <Text variant="heading.xxxl">Tell us more about yourself</Text>
        <Text>
          We need some more information before you can view this page.
        </Text>
      </TextStack>
      <Form
        {...form}
        columnCount={columnCount}
        onSubmit={() => {
          closeModal();
        }}
      />
    </Flex>
  );
});
