const formData = {
  action: 'None',
  formFields2Cols: [
    {
      id: 'c2c-sign-up-full-fullname',
      name: 'fullname',
      label: 'First and last name',
      placeholder: 'John Doe',
      required: false,
      type: 'Text',
    },
    {
      id: 'c2c-sign-up-full-jobtitle',
      name: 'job-title',
      label: 'job title',
      placeholder: 'Creative Director',
      required: false,
      type: 'Text',
    },
    {
      id: 'c2c-sign-up-full-company-name',
      name: 'company-name',
      label: 'Company name',
      placeholder: 'Google Inc.',
      required: false,
      type: 'Text',
    },
    {
      id: 'c2c-sign-up-full-current-customer',
      name: 'current-customer',
      label: 'Are you a current frame.io customer?',
      required: false,
      hasSelectPlaceholder: false,
      options: ['Yes', 'No'],
      type: 'Dropdown',
    },
    // {
    //     id: "c2c-sign-up-full-device-owned",
    //     name: "device-owned",
    //     label: "Select the device you own",
    //     required: false,
    //     options: [
    //         'Teradek Cube655',
    //         'Sound Devices Scorpio Recorder',
    //         'Sound Devices 888 Recorder',
    //         'Colorfront Express Dailies',
    //         'No but I’m interested in renting/purchasing for an upcoming project'
    //     ],
    //     type: "Dropdown"
    // },
  ],
  formFields1Col: [
    {
      id: 'c2c-sign-up-full-device-to-buy',
      name: 'device-to-buy',
      label: 'Which tools are you interested in/do you use in your workflow?',
      required: false,
      options: [
        'Teradek Cube655',
        'Sound Devices Scorpio Recorder',
        'Sound Devices 888 Recorder',
        'Colorfront Express Dailies',
        'None, but I’m interested in renting/purchasing for an upcoming project',
      ],
      type: 'Dropdown',
      hasSelectPlaceholder: false,
    },
    {
      id: 'c2c-sign-up-full-upcoming-project',
      name: 'upcoming-project',
      label:
        "Tell us about an upcoming project on which you'd like to use C2C.",
      placeholder: 'Your response here',
      required: false,
      type: 'Textarea',
    },
    {
      id: 'c2c-sign-up-full-workflow',
      name: 'workflow',
      label: 'Describe your on-set workflow',
      placeholder: 'Your response here',
      required: false,
      type: 'Textarea',
    },
  ],
  id: 'c2c-full-sign-up',
  media: null,
  slug: 'c2c-full-sign-up',
  submitButtonText: 'Submit',
  url: null,
};

export default formData;
