import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';
import { isFunction } from 'typical';
import css from 'src/theme/css';
import useSignUpUrl from 'src/hooks/useSignUpUrl';
import download from 'src/utils/download';
import navigate from 'src/utils/navigate';
import urls from 'src/utils/urls';
import { trackSubmit } from 'src/utils/analytics';
import ColorProvider from 'src/components/ColorProvider';
import FormField from 'src/components/FormField';
import SubmitButton from 'src/components/SubmitButton';
import { Flex } from 'src/components/FlexBox';
import Text from 'src/components/Text';

import * as S from './style';

const colorModeLookup = {
  inherit: 'light',
  light: 'light',
  dark: 'dark',
  lightBox: 'light',
  darkBox: 'dark',
  transparent: 'transparent',
};

const encode = (data, encoder) => {
  return Object.keys(data)
    .map((key) => encoder(key) + '=' + encoder(data[key]))
    .join('&');
};

const Form = ({
  slug,
  title,
  formFields,
  submitButtonText,
  submittedButtonText = 'Submitted',
  media,
  url,
  action,
  onSubmit,
  columnCount,
  variant,
  buttonCss = {},
  ...other
}) => {
  const signUpUrl = useSignUpUrl();
  const [disabled, setDisabled] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const actionLookup = {
    [actions.link]: () => void navigate(url),
    [actions.download]: () => void download(get(media, 'file.url')),
    [actions.logIn]: () => void navigate(urls.logIn),
    [actions.signUp]: () => void navigate(signUpUrl),
  };

  const submit = (data) => {
    if (disabled) return;

    setDisabled(true);

    // fetch('/', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //   body: encode({ 'form-name': slug, ...data }, window.encodeURIComponent),
    // });

    trackSubmit(data);

    if (isFunction(onSubmit)) {
      onSubmit();
    }

    if (isFunction(actionLookup[action])) {
      actionLookup[action]();
    }
  };

  const hasBox = variant === 'lightBox' || variant === 'darkBox';

  return (
    <ColorProvider mode={colorModeLookup[variant]}>
      <Flex
        flexDirection="column"
        gy={4}
        css={css({
          borderRadius: 2,
          p: hasBox ? 5 : 0,
          bg: hasBox ? 'antiMode' : 'transparent',
        })}
      >
        {title && (
          <Text variant="heading.m" textAlign="center">
            {title}
          </Text>
        )}
        <S.Form
          as="form"
          name={slug}
          method="post"
          data-netlify="true"
          onSubmit={handleSubmit(submit)}
          columnCount={columnCount}
          {...other}
        >
          <input type="hidden" name="form-name" value={slug} />

          {formFields.map(({ name, ...other }) => (
            <FormField
              {...other}
              key={name}
              name={name}
              error={get(errors, [name, 'message'])}
              register={register}
              disabled={disabled}
              variant={colorModeLookup[variant]}
            />
          ))}

          <SubmitButton
            state={disabled ? 'success' : 'initial'}
            label={disabled ? submittedButtonText : submitButtonText}
            disabled={disabled}
            css={css({
              cursor: disabled ? 'auto' : 'pointer',
              ...(buttonCss || {}),
            })}
          />
        </S.Form>
      </Flex>
    </ColorProvider>
  );
};

export default Form;

export const actions = {
  link: 'Link',
  download: 'Download',
  logIn: 'Log In',
  signUp: 'Sign Up',
};

export const query = graphql`
  fragment FormFragment on ContentfulForm {
    id
    slug
    title
    variant
    formFields {
      ...FormFieldFragment
    }
    submitButtonText
    submittedButtonText
    action
    url
    media {
      file {
        url
      }
    }
  }
`;
