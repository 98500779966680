import React from 'react';
import PropTypes from 'prop-types';

const ErrorExclamation = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path
        d="M8 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zM8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0z"
        fillRule="nonzero"
      />
      <path
        d="M7.25 4.25v4a.75.75 0 0 0 1.5 0v-4a.75.75 0 0 0-1.5 0z"
        fillRule="nonzero"
      />
      <path d="M8 10.5A.75.75 0 1 0 8 12a.75.75 0 0 0 0-1.5" />
    </g>
  </svg>
);

ErrorExclamation.propTypes = {
  className: PropTypes.string,
};

export default ErrorExclamation;
