import React from 'react';

export default ({ file, objectFit, objectPosition, ...other }) => {
  return (
    <div
      {...other}
      css={{ lineHeight: 0, overflow: 'hidden' }}
      dangerouslySetInnerHTML={{
        __html: `<video src="${
          file.url
        }" loop muted autoPlay playsInline style="object-fit: ${
          objectFit || 'cover'
        }; object-position: ${
          objectPosition || 'cover'
        }; width: 100%; height: 100%;">`,
      }}
    ></div>
  );
};
