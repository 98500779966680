import css from 'src/theme/css';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ArrowIcon from 'src/assets/svg/ArrowRight';
import { useBreakpoint } from 'src/components/Breakpoints';
import { Flex } from 'src/components/FlexBox';
import SmartButton, * as button from 'src/components/SmartButton';
import Tooltip from 'src/components/Tooltip';
import useSignUpUrl from 'src/hooks/useSignUpUrl';
import { trackSubmit } from 'src/utils/analytics';
import navigate from 'src/utils/navigate';
import regex from 'src/utils/regex';
import styled from 'styled-components';

const Input = ({ error, ...other }) => (
  <input
    {...other}
    css={css({
      flexGrow: 1,
      minWidth: 7,
      fontSize: 0,
      fontFamily: 'avenir',
      fontWeight: 'medium',
      color: 'blackAlpha.8',
      height: '3.2rem',
      p: 3,
      pr: [4, 3],
      bg: 'white',
      border: 'solid 1px',
      borderRadius: 1,
      borderColor: error ? 'red' : 'transparent',
      transition: 'border-color 200ms',
      ':focus': {
        borderColor: error ? 'red' : 'purpleLight',
        outline: 'none',
      },
      ':invalid': {
        boxShadow: 'none',
        outline: 'none',
      },
      '::placeholder': {
        color: 'blackAlpha.8',
      },
    })}
  />
);

const EmailInput = ({
  className,
  onSubmit,
  placeholder,
  buttonLabel,
  trackingData,
  ...other
}) => {
  const [inputValue, setInputValue] = useState('');
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState(false);
  const url = useSignUpUrl();
  const isLarge = useBreakpoint(0);

  const inputValueIsValid = regex.email.test(inputValue);
  const shouldDisplayError =
    inputValue.length > 0 && hasAttemptedSubmit && !inputValueIsValid;

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setShouldDisplayTooltip(false);
  };

  const handleTooltipClick = () => {
    setShouldDisplayTooltip(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputValueIsValid) {
      onSubmit(event);

      const parsedUrl = new URL(url);
      parsedUrl.hash = `e=${inputValue}`;
      trackSubmit({
        email: inputValue,
        ...trackingData,
      });
      navigate(parsedUrl.href);
    } else {
      setHasAttemptedSubmit(true);
      setShouldDisplayTooltip(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} {...other}>
      <Flex gx={3} css={{ position: 'relative' }}>
        <Tooltip
          hasIcon={false}
          show={shouldDisplayTooltip}
          onClick={handleTooltipClick}
          text="Please enter a valid email address."
        >
          <Input
            type="text"
            aria-label={placeholder}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            required
            error={shouldDisplayError}
          />
        </Tooltip>
        {isLarge ? (
          <SmartButton
            variant={button.variants.primary}
            onClick={handleSubmit}
            label={buttonLabel}
            aria-label="Submit email address"
            trackingData={trackingData}
          />
        ) : (
          <SmartButton
            onClick={handleSubmit}
            trackingData={trackingData}
            css={css({
              fill: 'purpleLight',
              size: '1.2rem',
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%',
            })}
          >
            <ArrowIcon css={css({ mt: 1 })} />
          </SmartButton>
        )}
      </Flex>
    </form>
  );
};

EmailInput.defaultProps = {
  onSubmit: () => {},
  placeholder: 'Enter your email',
  buttonLabel: 'Sign Up',
};

EmailInput.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  trackingData: PropTypes.object,
};

export default styled(EmailInput)``;
