import styled from 'styled-components';

export const Form = styled.div(
  ({ columnCount }) => `
  display: grid;
  grid-template-columns: repeat(${
    columnCount || 'auto-fill'
  }, minmax(auto, 24rem));
  grid-gap: 1.5rem 1rem;
  align-items: flex-end;
  justify-content: center;
  margin: 2rem 0;
  text-align: start;
`
);

export const Error = styled.p`
  ${(props) => props.theme.text.p}

  text-align: left;
`;
