import { Link as InternalLink } from 'gatsby';
import React from 'react';

import { trackClick } from 'src/utils/analytics';
import navigate from 'src/utils/navigate';

export default function Link({
  children,
  className,
  style,
  onClick,
  isDisabled,
  isExternal,
  isBlank,
  trackingData,
  url,
}) {
  const internalLink = (
    <InternalLink
      to={url}
      onClick={(event) => {
        if (isDisabled) return;
        trackClick(trackingData);
        onClick(event);
      }}
      className={className}
      style={{ textDecoration: 'none', ...style }}
    >
      {children}
    </InternalLink>
  );

  const externalLink = (
    <a
      href={url}
      target={isBlank ? '_blank' : ''}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        if (isDisabled) return;
        trackClick(trackingData);
        onClick(event);
        navigate(url, { isExternal, isBlank });
      }}
      className={className}
      style={{ textDecoration: 'none', ...style }}
    >
      {children}
    </a>
  );

  const isRelativePath = /^\/(?!\/)/.test(url);
  const isInternal = isRelativePath && !isBlank && !isExternal && !isDisabled;
  return isInternal ? internalLink : externalLink;
}

Link.defaultProps = {
  onClick: () => {},
  trackingData: {},
  url: '/',
};
