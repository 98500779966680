import React from 'react';
import PropTypes from 'prop-types';

const Dribbble = ({ className }) => (
  <svg className={className} viewBox="0 0 17 17">
    <path
      fillRule="evenodd"
      d="M3.874 14.1l.007.005-.007-.005zm.007.005c.025.017.103.07.159.117 1.394-3.033 5.7-4.538 5.7-4.538.01 0 .033-.01.044-.01 1.018 2.655 1.439 4.869 1.55 5.51a7.32 7.32 0 0 1-2.834.576c-1.76 0-3.365-.63-4.626-1.66l.007.005zm5.405-5.638c-.089.033-.188.055-.288.088 0 0-3.918 1.262-5.899 4.793A7.254 7.254 0 0 1 1.239 8.5c0-.077 0-.155.012-.232.121 0 3.707.088 7.448-1.03.21.41.41.82.587 1.229zM8.102 6.12c-3.487.92-6.52.886-6.707.886A7.272 7.272 0 0 1 5.4 1.936c.1.134 1.439 1.949 2.7 4.184zm7.57 3.531a7.29 7.29 0 0 1-3.11 4.87 32.404 32.404 0 0 0-1.417-5.19c2.347-.366 4.372.276 4.527.32zM6.762 1.45c-.01 0-.022.01-.022.01.011-.01.011-.01.022-.01zm6.53 1.605c-.022.033-1.051 1.605-3.785 2.623A35.456 35.456 0 0 0 6.785 1.45a7.205 7.205 0 0 1 6.508 1.605zm2.468 5.368c-.088-.012-2.556-.554-5.058-.233-.055-.122-.1-.254-.154-.376a30.46 30.46 0 0 0-.487-1.052c2.866-1.173 4.028-2.855 4.05-2.877a7.276 7.276 0 0 1 1.65 4.538zM17 8.5C17 3.807 13.193 0 8.5 0A8.502 8.502 0 0 0 0 8.5C0 13.193 3.807 17 8.5 17S17 13.193 17 8.5z"
    />
  </svg>
);

Dribbble.propTypes = {
  className: PropTypes.string,
};

export default Dribbble;
