import { isString } from 'typical';

export default (url, name) => {
  if (isString(url)) {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
  }
};
