import React from 'react';
import styled from 'styled-components';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';

const Outer = styled.div`
  position: relative;
`;

export default ({ ratio = 1, cover, fullWidth, children, ...other }) => {
  const [ref, { width, height }] = useMeasure({ polyfill: ResizeObserver });

  const f = cover ? Math.max : Math.min;
  const scale = f(width, height / ratio);

  return (
    <Outer
      {...other}
      ref={ref}
      css={{
        borderRadius: '12px',
      }}
    >
      <div
        style={{
          width: fullWidth ? '100%' : scale,
          height: fullWidth ? '' : scale * ratio,
          position: fullWidth ? '' : 'absolute',
          top: fullWidth ? '' : '50%',
          left: fullWidth ? '' : '50%',
          transform: fullWidth ? '' : 'translate(-50%, -50%)',
        }}
      >
        {children}
      </div>
    </Outer>
  );
};
