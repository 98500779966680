import React from 'react';
import { graphql } from 'gatsby';

import NavBar from './NavBar';

const Navigation = ({
  hasDarkNav,
  hasFixedNav,
  linkList,
  signUpLink,
  signInLink,
  goToAppLink,
  isTransparent,
  show,
  isSticky,
  variant,
}) => {
  return (
    <NavBar
      hasDarkNav={hasDarkNav}
      hasFixedNav={hasFixedNav}
      linkList={linkList}
      signUpLink={signUpLink}
      signInLink={signInLink}
      goToAppLink={goToAppLink}
      isTransparencyEnabled={isTransparent}
      show={show}
      isSticky={isSticky}
      variant={variant}
    />
  );
};

export default Navigation;

export const query = graphql`
  fragment NavFragment on ContentfulNav {
    linkList {
      id
      title
      contentNodes {
        ... on ContentfulLink {
          id
          label
          url
          trackingID
        }
      }
    }
    goToAppLink {
      label
      url
      trackingID
    }
    signUpLink {
      label
      url
      trackingID
    }
    signInLink {
      label
      url
      trackingID
    }
    variant
  }
`;
