import styled, { css } from 'styled-components';

import Tooltip from 'src/assets/svg/Tooltip';

export const Wrapper = styled.div`
  position: relative;
`;

export const TooltipIcon = styled(Tooltip)`
  fill: ${({ theme }) => theme.color.gray300};
  opacity: 0.5;
  height: 0.8rem;
  transition: opacity 100ms ${({ theme }) => theme.ease.short};
  width: 0.8rem;
  flex-shrink: 0;
`;

export const Text = styled.p(
  ({ theme }) => css`
    ${theme.text.avenirMedium};

    pointer-events: none;
    background-color: ${theme.color.black};
    border-radius: 6px;
    color: ${theme.color.white};
    display: none;
    font-size: 14px;
    line-height: 1.6;
    padding: 10px 12px;
    position: absolute;
    left: 50%;
    top: -1rem;
    transform: translate(-50%, -100%);
    width: 16rem;
    z-index: 10;
    box-shadow: ${theme.shadow.light};
    border: solid 1px ${theme.color.gray800};

    &::before {
      border-right: solid 1px ${theme.color.gray800};
      border-bottom: solid 1px ${theme.color.gray800};
      background-color: ${theme.color.black};
      content: '';
      width: 1rem;
      height: 1rem;
      bottom: -0.55rem;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) rotate(45deg);
    }
  `
);

export const TriggerBtn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > * + *:not(${Text}) {
    margin-left: 0.5rem;
  }

  &:hover {
    ${TooltipIcon} {
      opacity: 1;
    }

    ${Text} {
      display: block;
    }
  }
`;
