import React from 'react';
import styled, { css } from 'styled-components';

import PlayIcon from 'src/assets/svg/circle-icon-play.inline.svg';
import PlayIconSmall from 'src/assets/svg/circle-icon-play-small.inline.svg';
import ArrowIcon from 'src/assets/svg/circle-icon-arrow.inline.svg';
import ArrowIconSmall from 'src/assets/svg/circle-icon-arrow-small.inline.svg';
import TextIcon from 'src/assets/svg/circle-icon-text.inline.svg';

const Wrapper = styled.div(
  ({ isSmall, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 0, 0);

    svg,
    .circle,
    .icon {
      transition-duration: ${theme.duration.medium};
      transition-timing-function: ${theme.ease.medium};
    }

    svg {
      transform: scaleZ(1);

      width: ${isSmall ? '1.75rem' : '4rem'};
      height: ${isSmall ? '1.75rem' : '4rem'};

      transition-property: transform;
    }

    .circle {
      stroke: currentColor;
      fill: transparent;
      --hover-fill: ${theme.mode === 'dark'
        ? theme.color.white
        : theme.color.purpleLight};
      --hover-stroke: ${theme.mode === 'dark'
        ? theme.color.white
        : theme.color.purpleLight};
      transition-property: stroke, fill;
    }

    .icon {
      stroke: currentColor;
      fill: currentColor;
      --hover-color: ${theme.mode === 'dark'
        ? theme.color.purpleLight
        : theme.color.white};
      transition-property: stroke, fill;
    }
  `
);

export const hoverStyles = {
  [Wrapper]: {
    svg: {
      transform: 'scaleZ(1.1)',
    },
    '.circle': {
      stroke: 'var(--hover-stroke)',
      fill: 'var(--hover-fill)',
    },
    '.icon': {
      stroke: 'var(--hover-color)',
      fill: 'var(--hover-color)',
    },
  },
};

export const hover = css`
  ${Wrapper} {
    svg {
      transform: scale(1.1);
    }

    .circle {
      stroke: var(--hover-stroke);
      fill: var(--hover-fill);
    }

    .icon {
      fill: var(--hover-color);
      stroke: var(--hover-color);
    }
  }
`;

const iconLookup = {
  arrow: ArrowIcon,
  play: PlayIcon,
  text: TextIcon,
};

const smallIconLookup = {
  arrow: ArrowIconSmall,
  play: PlayIconSmall,
  text: TextIcon,
};

export default ({ type, isSmall, ...other }) => {
  const Icon = isSmall ? smallIconLookup[type] : iconLookup[type];

  return (
    <Wrapper isSmall={isSmall} {...other}>
      {Icon && <Icon />}
    </Wrapper>
  );
};
