import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ className }) => (
  <svg className={className} viewBox="0 0 41 40">
    <path
      fillRule="evenodd"
      d="M24.968 20l15.786 15.786L36.54 40 20.754 24.214 4.968 40 .754 35.786 16.54 20 .754 4.214 4.968 0l15.786 15.786L36.54 0l4.214 4.214L24.968 20z"
    />
  </svg>
);

Close.propTypes = {
  className: PropTypes.string,
};

export default Close;
