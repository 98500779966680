import React from 'react';
import css from 'src/theme/css';

import Text from 'src/components/Text';

const Table = ({ table: { tableData } }) => (
  <table
    css={css({
      textAlign: 'left',
    })}
  >
    <thead>
      <tr
        css={css({
          border: 'solid 1px',
          borderColor: 'gray300',
          bg: 'gray100',
        })}
      >
        {tableData[0].map((cell) => (
          <th key={cell} css={css({ p: 2 })}>
            <Text variant="caps">{cell}</Text>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {tableData.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return null;
        }
        return (
          <tr
            key={rowIndex}
            css={css({ border: 'solid 1px', borderColor: 'gray300' })}
          >
            {row.map((cell) => (
              <td key={cell} css={css({ p: 2 })}>
                <Text variant="small">{cell}</Text>
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default Table;
