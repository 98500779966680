import React from 'react';
import PropTypes from 'prop-types';

import { trackClick } from 'src/utils/analytics';

export default function Button({
  children,
  className,
  onClick,
  trackingData,
  type,
}) {
  return (
    <button
      type={type || 'button'}
      onClick={(event) => {
        trackClick(trackingData);
        onClick(event);
      }}
      className={className}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  trackingData: {},
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  trackingData: PropTypes.object,
};
