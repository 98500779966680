import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import Button from 'src/components/Button';

import * as actionComponents from './actions';
import * as variantComponents from './variants';

export const variants = {
  dinosaurPrimary: 'DinosaurPrimary',
  primary: 'Primary',
  simple: 'Simple',
  secondary: 'Secondary',
  knockout: 'Knockout',
  arrow: 'Arrow',
  play: 'Play',
  appStore: 'App Store',
  apple: 'Apple',
  windows: 'Windows',
  caps: 'Caps',
  c2c: 'C2C',
  c2cPlay: 'C2CPlay',
  c2cPlaySmall: 'C2CPlaySmall',
  underline: 'Underline',
};

export const actions = {
  link: 'Link',
  download: 'Download',
  signIn: 'Log In',
  signUp: 'Sign Up',
  goToApp: 'Go to App',
  videoModal: 'Video Modal',
  calendlyModal: 'Calendly Modal',
  twitterShare: 'Twitter Share',
  twitterFollow: 'Twitter Follow',
  appStore: 'App Store',
};

const actionLookup = {
  [actions.goToApp]: actionComponents.GoToApp,
  [actions.link]: actionComponents.Link,
  [actions.download]: actionComponents.Download,
  [actions.signIn]: actionComponents.LogIn,
  [actions.signUp]: actionComponents.SignUp,
  [actions.videoModal]: actionComponents.VideoModal,
  [actions.calendlyModal]: actionComponents.CalendlyModal,
  [actions.twitterShare]: actionComponents.TwitterShare,
  [actions.twitterFollow]: actionComponents.TwitterFollow,
  [actions.appStore]: actionComponents.AppStore,
};

const variantLookup = {
  [variants.simple]: variantComponents.Simple,
  [variants.primary]: variantComponents.Primary,
  [variants.dinosaurPrimary]: variantComponents.DinosaurPrimary,
  [variants.secondary]: variantComponents.Secondary,
  [variants.knockout]: variantComponents.Knockout,
  [variants.arrow]: variantComponents.Arrow,
  [variants.play]: variantComponents.Play,
  [variants.appStore]: variantComponents.AppStore,
  [variants.apple]: variantComponents.Apple,
  [variants.windows]: variantComponents.Windows,
  [variants.caps]: variantComponents.Caps,
  [variants.c2c]: variantComponents.C2C,
  [variants.c2cPlay]: variantComponents.C2CPlay,
  [variants.c2cPlaySmall]: variantComponents.C2CPlaySmall,
  [variants.underline]: variantComponents.Underline,
};

export default function SmartButton({
  action,
  variant,
  label,
  trackingId,
  trackingData,
  children,
  ...other
}) {
  const props = {
    trackingData: {
      ...trackingData,
      title: trackingId || label,
    },
    ...other,
  };

  const Action = get(actionLookup, action, Button);
  const Variant = get(variantLookup, variant);

  return (
    <Action {...props}>
      {Variant ? <Variant label={label} {...props} /> : <>{children}</>}
    </Action>
  );
}

SmartButton.defaultProps = {
  trackingData: {},
};

export const query = graphql`
  fragment ButtonFragment on ContentfulButton {
    id
    label
    action
    variant
    url
    media {
      file {
        url
      }
    }
    videoUrl
    trackingId
  }
`;
