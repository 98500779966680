// DEPRECATED

import styled from 'styled-components';
import React from 'react';

const Inner = styled.div(
  (props) => `
  display: flex;
  flex-wrap: ${props.wrap};
  justify-content: ${props.justifyContent};
  align-items: ${props.alignItems};
  margin: calc(${props.spacingY} / 2 * -1) calc(${props.spacingX} / 2 * -1);

  & > * {
    margin: calc(${props.spacingY} / 2) calc(${props.spacingX} / 2);
  }
`
);

const Cluster = ({ alignItems, children, justifyContent, spacing, wrap }) => (
  <div>
    <Inner
      wrap={wrap ? 'wrap' : 'nowrap'}
      alignItems={alignItems}
      justifyContent={justifyContent}
      spacingY={spacing instanceof Array ? spacing[0] : spacing}
      spacingX={spacing instanceof Array ? spacing[1] : spacing}
    >
      {children}
    </Inner>
  </div>
);

Cluster.defaultProps = {
  alignItems: 'inherit',
  justifyContent: 'inherit',
  spacing: '0px',
  wrap: true,
};

export default Cluster;
