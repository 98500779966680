import { useState, useEffect } from 'react';
import isWindowDefined from 'src/utils/isWindowDefined';

// https://support.google.com/optimize/answer/9059383?hl=en
export function getExperimentVariation(name) {
  if (isWindowDefined && window.google_optimize) {
    return new Promise(resolve => {
      window.dataLayer.push('event', 'optimize.callback', {
        name,
        callback: resolve,
      });
    });
  }

  return Promise.resolve(0, name);
}

export function useExperimentVariation(name) {
  const [variation, setVariation] = useState('');

  useEffect(() => {
    getExperimentVariation(name).then(setVariation);
  }, [name]);

  return variation;
}

export function useExperimentValue(key) {
  const [value, setValue] = useState();

  useEffect(() => {
    getExperimentVariation().then(() => {
      setValue(window[key]);
    });
  }, [key]);

  return value;
}

export default {
  // Experiment IDs go here.
};
