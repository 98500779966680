import { useState, useEffect } from 'react';
import cond from 'lodash/cond';
import { isArrayLike } from 'typical';

import regex from 'src/utils/regex';

export const passSearch = (source, dest) => {
  try {
    const parsedSource = new URL(source);
    const parsedDest = new URL(dest);
    parsedDest.search = parsedSource.search;
    return parsedDest.href;
  } catch (e) {
    return source;
  }
};

const test = (r) => (url) => isArrayLike(url.match(r));
const match = (r, host) => (url) => ({ host, id: url.match(r)[1] });

export const parseVideo = cond([
  [test(regex.youtube), match(regex.youtube, 'youtube')],
  [test(regex.wistia), match(regex.wistia, 'wistia')],
]);

export const useHasAdAttribution = () => {
  const [hasAttribution, setHasAttribution] = useState(false);

  useEffect(() => {
    const parsedLocation = new URL(window.location);
    setHasAttribution(parsedLocation.searchParams.get('utm_medium') === 'cpc');
  }, [setHasAttribution]);

  return hasAttribution;
};

export default {
  signUpReferral: 'https://accounts.frame.io/promotions/RAF8675309AKE',
  signUp: 'https://accounts.frame.io/welcome',
  goToApp: 'https://app.frame.io/',
  signUpFromAd: 'https://accounts.frame.io/promotions/CPCTM7DV4',
  logIn: 'https://app.frame.io',
};
