// DEPRECATED

import styled, { css } from 'styled-components';
import get from 'lodash/get';

export const alignments = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
};

export const justifications = {
  start: 'start',
  center: 'center',
  end: 'end',
  stretch: 'stretch',
  spaceBetween: 'space-between',
  spaceAround: 'space-around',
  spaceEvenly: 'space-evenly',
};

export const directions = {
  column: 'column',
  columnReverse: 'column-reverse',
  row: 'row',
  rowReverse: 'row-reverse',
};

const textAlignLookup = {
  [alignments.start]: 'start',
  [alignments.center]: 'center',
  [alignments.end]: 'end',
  [alignments.stretch]: 'start',
};

const sideLookup = {
  [directions.column]: 'top',
  [directions.columnReverse]: 'bottom',
  [directions.row]: 'left',
  [directions.rowReverse]: 'right',
};

export default styled.div(
  ({ alignment, justification, direction, spacing }) => {
    const side = get(sideLookup, direction, 'top');

    return css`
      display: flex;
      flex-wrap: nowrap;
      flex-direction: ${direction || 'column'};
      justify-content: ${justification || 'flex-start'};
      align-items: ${alignment || 'stretch'};
      text-align: ${get(textAlignLookup, alignment, 'start')};

      > * {
        ${justification === justifications.stretch && `flex: 100% 1 1`};
        margin: 0;
      }

      > * + * {
        margin-${side}: ${spacing || '0px'};
      }
    `;
  }
);
