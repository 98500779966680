import styled, { css } from 'styled-components';

import { listReset } from 'src/utils/mixins';
import { minWidth } from 'src/utils/breakpoints';
import ExternalLink from 'src/assets/svg/ExternalLink';
import FrameLogo from 'src/assets/svg/FrameLogo';
import FrameWordmark from 'src/assets/svg/FrameWordmark';
import Hamburger from 'src/assets/svg/Hamburger';
import Link from 'src/components/Link';

export const Wrapper = styled.nav(
  ({ theme, isTransparent, isVisible, isSticky }) => css`
    position: ${isSticky ? 'sticky' : 'relative'};
    top: 0;
    left: 0;
    z-index: ${theme.zIndex.nav};
    width: 100%;

    transition-property: background, transform;
    transition-timing-function: ${theme.ease.inOutCubic};
    transition-duration: 0.5s;

    background: ${isTransparent ? theme.color.black00 : theme.color.black};
    transform: ${isVisible ? 'translateY(0)' : 'translateY(-100%)'};
  `
);

export const Inner = styled.div(
  ({ theme }) => css`
    height: ${theme.layout.headerHeight};
  `
);

export const NavItems = styled.ul(
  ({ theme, hasBorder }) => css`
    ${listReset()}

    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    transition-property: border-color;
    transition-duration: 0.5s;
    border-bottom-color: ${hasBorder
      ? theme.color.white10
      : theme.color.white00};

    @media ${minWidth.m} {
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
  `
);

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Logo = styled(FrameLogo)`
  height: 2rem;
  width: 2rem;
`;

export const HamburgerIcon = styled(Hamburger)`
  fill: ${({ theme }) => theme.color.white};
  height: 1.5rem;
  margin-top: 2px;
  transition: fill 100ms ${({ theme }) => theme.ease.short};
`;

export const HamburgerBtn = styled.button`
  &:hover {
    ${HamburgerIcon} {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

export const Wordmark = styled(FrameWordmark)`
  fill: ${({ theme }) => theme.color.white};
  height: 2rem;
  margin-bottom: 0.25em;
`;

export const SignUpIcon = styled(ExternalLink)`
  fill: ${({ theme }) => theme.color.white};
  flex-shrink: 0;
  height: 11px;
  margin: 0 0 3px 10px;
  transition: fill 100ms ${({ theme }) => theme.ease.short};
  width: 11px;
`;
