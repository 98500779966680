import React from 'react';
import styled from 'styled-components';
import css from 'src/theme/css';

import { minWidth } from 'src/utils/breakpoints';

import Text from 'src/components/Text';
import Stack from 'src/components/Stack';
import DoubleQuote from 'src/assets/svg/double-quote.inline.svg';
import useMediaQuery from 'src/hooks/useMediaQuery';

const Quote = styled.blockquote(
  ({ theme }) => `
    border-top: solid ${theme.color.black10} 1px;
    border-bottom: solid ${theme.color.black10} 1px;
    margin: 0;
    padding: 4rem 0;

    @media ${minWidth.m} {
      padding: 4rem 0;
    }
  `
);

const StyledDoubleQuote = styled(DoubleQuote)(
  ({ theme }) => `
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    fill: ${theme.currentColor.highlight};
  `
);

export default ({ text, customerName, customerDescription }) => {
  const isSmall = useMediaQuery(minWidth.s);

  return (
    <Quote>
      <Stack direction="row" spacing="2rem">
        {isSmall && <StyledDoubleQuote />}
        <Stack spacing="1rem">
          <Text variant="heading.m" css={css({ fontSize: 3 })}>
            {text}
          </Text>
          <Stack direction="row" spacing="1em">
            <Text variant="small">
              <b>{customerName}</b>
            </Text>
            <Text variant="small">{customerDescription}</Text>
          </Stack>
        </Stack>
      </Stack>
    </Quote>
  );
};
