import React, { useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & .calendly-inline-widget {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    transform: translateZ(0);
    width: 300px;
    height: 480px;
  }
`;

export default forwardRef((_, ref) => {
  const wrapper = useRef(null);

  useEffect(() => {
    if (wrapper.current) {
      const calendlyScript = document.createElement('script');
      calendlyScript.src =
        'https://assets.calendly.com/assets/external/widget.js';
      wrapper.current.appendChild(calendlyScript);
    }
  }, [wrapper]);

  return (
    <div ref={ref}>
      <Wrapper ref={wrapper}>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/frame_io/enterprise?hide_event_type_details=1&primary_color=5a52ff"
        />
      </Wrapper>
    </div>
  );
});
