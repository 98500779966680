import React, { Component } from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';

import Text from 'src/components/Text';
import ChevronRight from 'src/assets/svg/ChevronRight';

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.itemDescriptionRefs = Array.from({ length: props.items.length }, () =>
      React.createRef()
    );

    this.state = {
      activeItemIndex: -1,
    };
  }

  render() {
    const { items } = this.props;

    return (
      <ul css={css({ listStyle: 'none', margin: 0, padding: 0 })}>
        {items.map((item, index) => this.renderItem(item, index))}
      </ul>
    );
  }

  renderItem = (item, index) => {
    const { activeItemIndex } = this.state;

    const itemDescriptionStyle = {};
    const isActive = activeItemIndex === index;

    if (isActive) {
      itemDescriptionStyle.maxHeight = this.itemDescriptionRefs[
        index
      ].current.offsetHeight;
    }

    return (
      <li
        key={item.id}
        css={css({ borderBottom: 'solid 1px', borderColor: 'modeAlpha.3' })}
      >
        <button
          onClick={() => this.handleToggleClick(index)}
          css={css({ display: 'block', width: '100%' })}
        >
          <Text
            fontFamily="aktiv"
            fontSize={3}
            color="white"
            lineHeight={1.3}
            textAlign="start"
            css={css({
              display: 'flex',
              alignItems: 'center',
              py: '1.5rem',
              letterSpacing: '-0.03em',
            })}
          >
            {item.title}
            <ChevronRight
              css={css({
                flexShrink: 0,
                ml: 'auto',
                size: 3,
                fill: 'currentcolor',
                transition: 'transform 500ms',
                transitionTimingFunction: ({ ease }) => ease.expo,
                transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
              })}
            />
          </Text>
        </button>
        <div
          style={itemDescriptionStyle}
          css={css({
            maxHeight: 0,
            overflow: 'hidden',
            transition: 'max-height 500ms',
            transitionTimingFunction: ({ ease }) => ease.expo,
          })}
        >
          <Text
            ref={this.itemDescriptionRefs[index]}
            dangerouslySetInnerHTML={{
              __html: item.description.childMarkdownRemark.html,
            }}
            css={css({
              paddingBottom: 4,
              maxWidth: '60rem',
              transition: 'opacity 500ms',
              transitionTimingFunction: ({ ease }) => ease.expo,
              opacity: isActive ? 1 : 0,
            })}
          />
        </div>
      </li>
    );
  };

  handleToggleClick = (index) => {
    const { activeItemIndex } = this.state;

    const newIndex = index === activeItemIndex ? -1 : index;

    this.setState({
      activeItemIndex: newIndex,
    });
  };
}

export default Accordion;

export const query = graphql`
  fragment AccordionFragment on ContentfulAccordion {
    id
    items {
      id
      title
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
