import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

export default ({ title, description, image }) => {
  const imageUrl = image
    ? (image.file.url.startsWith('//') ? 'http:' : '') + image.file.url
    : null;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={'Frame.io'} />
        <meta property="og:url" content={'https://frame.io'} />
        <meta property="og:description" content={description.description} />
        <meta name="description" content={description.description} />
        <meta name="twitter:card" content={'summary'} />
        <meta name="twitter:description" content={description.description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:site" content={'@Frame_io'} />
      </Helmet>

      {image && (
        <Helmet>
          <meta property="og:image:alt" content={image.description} />
          <meta property="og:image" content={image.file.url} />
          <meta property="og:image:type" content={image.file.contentType} />
          <meta
            property="og:image:width"
            content={image.file.details.image.width}
          />
          <meta
            property="og:image:height"
            content={image.file.details.image.height}
          />
          <meta name="twitter:image" content={imageUrl} />
        </Helmet>
      )}
    </>
  );
};

export const query = graphql`
  fragment PageMetaFragment on ContentfulPageMeta {
    title
    description {
      description
    }
    image {
      description
      file {
        url
        contentType
        details {
          image {
            height
            width
          }
        }
      }
    }
  }
`;
