import React from 'react';
import css from 'src/theme/css';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Flex, Box } from 'src/components/FlexBox';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import CloseIcon from 'src/assets/svg/Close';

const referralDays = 14;

const Banner = ({ onClose, children, ...buttonProps }) => {
  return (
    <Box
      css={css({
        position: 'relative',
        zIndex: 'banner',
        color: 'white',
        background: 'purpleLight',
        a: {
          color: 'currentcolor',
        },
      })}
    >
      <Box
        css={css({
          width: '100%',
          maxWidth: 'pageMaxWidth',
          px: 'pageMargin',
          py: 2,
          m: 'auto',
          justifyContent: 'space-between',
        })}
      >
        <Flex gx={2} flexWrap="wrap">
          <Text variant="small" color="white">
            {children}
          </Text>
          <SmartButton variant="Caps" {...buttonProps} />
        </Flex>
        <Button onClick={onClose}>
          <CloseIcon
            css={{
              fill: 'currentcolor',
              height: '12px',
              width: '12px',
              marginTop: '0.5rem',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default () => {
  return (
    <div>
      {false && (
        <Banner action={smartButton.actions.signUp} label="Redeem now">
          Your friend gifted you {referralDays} days of Frame.io.
        </Banner>
      )}
    </div>
  );
};
