import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ className }) => (
  <svg className={className} viewBox="0 0 11 11">
    <g fillRule="evenodd">
      <path d="M5 2v2H2v5h5V6h2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3z" />
      <path
        fillRule="nonzero"
        d="M9 3.414L5.207 7.207a1 1 0 0 1-1.414-1.414l3.784-3.784-.545.001a1 1 0 0 1-.007-2L9.996 0a.997.997 0 0 1 .711.293A.997.997 0 0 1 11 1v2.96a1 1 0 0 1-2 0v-.546z"
      />
    </g>
  </svg>
);

ExternalLink.propTypes = {
  className: PropTypes.string,
};

export default ExternalLink;
