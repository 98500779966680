import React from 'react';
import { isValidElementType } from 'react-is';
import PropTypes from 'prop-types';

const OptionalWrapper = ({ Component, children, ...other }) => {
  if (isValidElementType(Component)) {
    return <Component {...other}>{children}</Component>;
  }

  return <>{children}</>;
};

OptionalWrapper.propTypes = {
  Component: PropTypes.elementType,
  children: PropTypes.node,
};

export default OptionalWrapper;
