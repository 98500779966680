import React from 'react';
import { graphql } from 'gatsby';
import GatsbyImg from 'gatsby-image/withIEPolyfill';
import css from 'src/theme/css';
import MovingImg from 'src/components/MovingImg';
import { Box } from 'src/components/FlexBox';
import { useBreakpoint } from 'src/components/Breakpoints';
import useAwaitImageLoad from '../../hooks/useAwaitImageLoad';
import Link from 'src/components/Link';

const Img = (props) => {
  const { onLoad, onStartLoad } = useAwaitImageLoad();
  return props.fluid || props.fixed ? (
    <GatsbyImg
      {...props}
      onLoad={() => onLoad(props.title)}
      onStartLoad={(wasCached) => onStartLoad(props.title, wasCached)}
    />
  ) : props.file ? (
    <picture>
      <source
        srcSet={`${props.file.url}?w=2000&q=50`}
        media="(min-width: 1600px)"
      />
      <source
        srcSet={`${props.file.url}?w=1600&q=50`}
        media="(min-width: 1200px)"
      />
      <source
        srcSet={`${props.file.url}?w=1200&q=50`}
        media="(min-width: 800px)"
      />
      <img
        css={{ maxWidth: '100%' }}
        src={`${props.file.url}?w=800&q=50`}
        alt={props.description}
      />
    </picture>
  ) : (
    <div />
  );
};

export default ({
  color,
  smallImage,
  objectFit,
  objectPosition,
  largeImage,
  video,
  other,
  clickthroughUrl,
  ...props
}) => {
  const isLarge = useBreakpoint(1);
  const children =
    isLarge && video ? (
      <MovingImg
        {...video}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
    ) : isLarge && largeImage ? (
      <Img
        {...largeImage}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
    ) : !isLarge && smallImage ? (
      <Img
        {...smallImage}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
    ) : (
      <></>
    );
  const containerCss = css({
    ...other,
    overflow: 'hidden',
    bg: color,
    lineHeight: 0,
    '> *': {
      width: '100%',
      height: '100%',
    },
  });
  return clickthroughUrl ? (
    <Link css={containerCss} url={clickthroughUrl} {...props}>
      {children}
    </Link>
  ) : (
    <Box css={containerCss} {...props}>
      {children}
    </Box>
  );
};

export const query = graphql`
  fragment ImageGroupFragment on ContentfulImageGroup {
    color
    clickthroughUrl
    smallImage {
      title
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    largeImage {
      title
      fluid(maxWidth: 2880, quality: 90) {
        ...GatsbyContentfulFluid_noBase64
      }
      file {
        details {
          image {
            width
            height
          }
        }
      }
    }
    video {
      file {
        url
      }
    }
    other {
      boxShadow
      borderTopRightRadius
      borderTopLeftRadius
      borderBottomRightRadius
      borderBottomLeftRadius
      borderRadius
    }
  }
`;
