import React, { forwardRef } from 'react';
import css from 'src/theme/css';
import { useStaticQuery, graphql } from 'gatsby';
import Form from 'src/components/Form';
import Text from 'src/components/Text';
import Layers from 'src/components/Layers';
import { Box } from 'src/components/FlexBox';

export default forwardRef((_, ref) => {
  const { form } = useStaticQuery(graphql`
    query {
      form: contentfulForm(slug: { eq: "c2c-stay-up-to-date" }) {
        ...FormFragment
      }
    }
  `);

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      css={css({
        position: 'relative',
        px: 'pageMargin',
        py: 'sectionMargin',
        minHeight: '100vh',
      })}
    >
      <Layers ref={ref}>
        <Box
          css={css({
            bg: 'c2cGlow',
            backgroundSize: 'cover',
            filter: 'blur(3rem)',
            opacity: 0.25,
          })}
        />
        <Box
          flexDirection="column"
          css={css({
            position: 'relative',
            bg: 'black',
            borderRadius: 1,
            p: 4,
          })}
        >
          <Text variant="aktivLarge">Stay up to date.</Text>
          <Text variant="aktiv" css={css({ pt: 2 })}>
            Never miss an update from Frame.io C2C.
          </Text>
          <Form
            {...form}
            variant={'c2c'}
            buttonCss={{ color: 'black', backgroundColor: 'white' }}
            css={css({ pt: '1.5rem', margin: 0 })}
          />
        </Box>
      </Layers>
    </Box>
  );
});
