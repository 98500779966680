import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { flexbox, grid, system, color, typography } from 'styled-system';

const Inner = styled.div(
  {
    '--gx': '0px',
    '--gy': '0px',
    display: 'flex',
    margin: 'calc(var(--gy) / -2) calc(var(--gx) / -2)',
    '> *': {
      margin: 'calc(var(--gy) / 2) calc(var(--gx) / 2) !important',
    },
  },
  flexbox,
  system({
    g: { properties: ['--gx', '--gy'], scale: 'space' },
    gx: { property: '--gx', scale: 'space' },
    gy: { property: '--gy', scale: 'space' },
  })
);

export const Box = styled.div(
  (props) => `
    display: flex;
    padding-top: ${props.paddingTop};
    padding-bottom: ${props.paddingBottom};
  `,
  grid,
  flexbox,
  color,
  typography
);

export const Box2 = styled.div`
  display: 'flex', ${{ grid, flexbox, color, typography }};
`;

export const Flex = forwardRef(
  (
    {
      children,
      flexWrap,
      flexDirection,
      alignItems,
      alignContent,
      justifyContent,
      g,
      gx,
      gy,
      ...outerProps
    },
    ref
  ) => {
    const innerProps = {
      flexWrap,
      flexDirection,
      alignItems,
      alignContent,
      justifyContent,
      g,
      gx,
      gy,
    };
    return (
      <Box {...outerProps} flexDirection="column" ref={ref}>
        <Inner {...innerProps}>{children}</Inner>
      </Box>
    );
  }
);

export const Grid = styled.div(
  {
    display: 'grid',
  },
  grid,
  flexbox,
  system({
    g: { property: 'gridGap', scale: 'space' },
    gx: { property: 'gridColumnGap', scale: 'space' },
    gy: { property: 'gridRowGap', scale: 'space' },
  })
);
