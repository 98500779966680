/* Deprecated: use src/theme/index or src/components/Breakpoints */

import mapValues from 'lodash/mapValues';

const breakpoints = {
  xs: 250,
  s: 640,
  m: 960,
  l: 1280,
  xl: 1920,
};

export const minWidth = mapValues(
  breakpoints,
  (value) => `(min-width: ${value}px)`
);

export const maxWidth = mapValues(
  breakpoints,
  (value) => `(max-width: ${value - 1} px)`
);

export const mobile = 'not all and (hover:hover)';

export default breakpoints;
