import React from 'react';
import get from 'lodash/get';
import css from 'src/theme/css';
import Text from 'src/components/Text';

import BoxLogo from 'src/assets/svg/logos/box.inline.svg';
import DropboxLogo from 'src/assets/svg/logos/dropbox.inline.svg';
import FrameioLogo from 'src/assets/svg/logos/frameio.inline.svg';
import VimeoLogo from 'src/assets/svg/logos/vimeo.inline.svg';

const iconLookup = {
  boxLogo: BoxLogo,
  dropboxLogo: DropboxLogo,
  frameioLogo: FrameioLogo,
  vimeoLogo: VimeoLogo,
};

export default ({ identifier, name, media, ...other }) => {
  let Icon;

  const inCMS = !iconLookup[identifier];

  if (!inCMS) {
    Icon = get(iconLookup, identifier, () => <Text>{name}</Text>);
  }

  return inCMS ? (
    <div
      {...other}
      css={css({
        transform: ['scale(0.8)', 'none'],
      })}
    >
      {media && <img src={media.file.url} />}
    </div>
  ) : (
    <div
      {...other}
      css={css({
        color: 'currentColor',
        fill: 'currentColor',
        transform: ['scale(0.8)', 'none'],
      })}
    >
      <Icon />
    </div>
  );
};
