import React from 'react';
import styled, { css } from 'styled-components';

import { parseVideo } from 'src/utils/urls';

import Frame from 'src/components/Frame';

import YoutubePlayer from './YoutubePlayer';
import WistiaPlayer from './WistiaPlayer';

const StyledFrame = styled(Frame)(
  ({ theme }) => css`
    border: solid 1px ${theme.color.gray800};
    background: ${theme.color.black};
  `
);

const componentLookup = {
  youtube: YoutubePlayer,
  wistia: WistiaPlayer,
};

export default React.forwardRef(({ url, autoplay, ...other }, ref) => {
  const { host, id } = parseVideo(url);
  const Component = componentLookup[host];
  return (
    <StyledFrame ratio={9 / 16} {...other} ref={ref}>
      <Component id={id} autoplay={autoplay} />
    </StyledFrame>
  );
});
