import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import regex from 'src/utils/regex';
import { isDefined } from 'typical';
import css from 'src/theme/css';
import { Flex, Box } from 'src/components/FlexBox';
import Text from 'src/components/Text';

const defaultCss = () => ({
  position: 'relative',
  appearance: 'none',
  boxSizing: 'border-box',
  fontSize: 0,
  fontFamily: 'avenir',
  fontWeight: 'medium',
  width: '100%',
  height: '3.2rem',
  px: 3,
  transition: 'border-color 500ms',
  border: 'solid 1px',
  borderRadius: 1,
  ':invalid': {
    outline: 'none',
    boxShadow: 'none',
  },
  pr: 4,
  resize: 'vertical',
});

const variantCssLookup = {
  c2c: ({ disabled, error }) => ({
    ...defaultCss({ disabled }),
    bg: disabled ? 'gray900' : 'black',
    color: disabled ? 'gray500' : 'white',
    borderColor: error ? 'red' : 'transparent',
    zIndex: 2,
    ':focus': {
      borderColor: ({ mode }) => (mode === 'dark' ? 'white' : 'purpleLight'),
      outline: 'none',
    },
    '::placeholder': {
      color: 'white',
      opacity: 0.4,
    },
  }),
  light: ({ disabled, error }) => ({
    ...defaultCss({ disabled }),
    bg: disabled ? 'gray300' : 'white',
    color: disabled ? 'gray500' : 'black',
    borderColor: error ? 'red' : 'gray300',
    ':focus': {
      borderColor: ({ mode }) => (mode === 'dark' ? 'white' : 'purpleLight'),
      outline: 'none',
    },
    '::placeholder': {
      color: 'black',
      opacity: 0.5,
    },
  }),
  dark: ({ disabled, error }) => ({
    ...defaultCss({ disabled }),
    bg: disabled ? 'gray900' : 'black',
    color: disabled ? 'gray500' : 'white',
    borderColor: error ? 'red' : 'whiteAlpha.2',
    ':focus': {
      borderColor: ({ mode }) => (mode === 'dark' ? 'white' : 'purpleLight'),
      outline: 'none',
    },
    '::placeholder': {
      color: 'white',
      opacity: 0.4,
    },
  }),
  transparent: ({ disabled, error }) => ({
    ...defaultCss({ disabled }),
    bg: 'transparent',
    color: 'white',
    borderColor: error ? 'white' : 'rgba(255, 255, 255, 0.5)',
    ':focus': {
      borderColor: 'white',
      outline: 'none',
    },
    '::placeholder': {
      color: 'white',
      opacity: '1',
    },
  }),
};

const variantInputBoxCssLookup = {
  light: {},
  dark: {},
  c2c: {
    ':after': {
      position: 'absolute',
      top: '-1px',
      bottom: '-1px',
      left: '-1px',
      right: '-1px',
      background:
        'linear-gradient(90deg, #8FC8F2 -0.22%, #DC30EF 34.7%, #2C1EE2 100.2%)',
      content: `''`,
      zIndex: 0,
      borderRadius: '0.5rem',
    },
  },
};

const FormField = ({
  error,
  label,
  labelCss = {},
  name,
  options,
  placeholder,
  hasSelectPlaceholder = true,
  type,
  required,
  register,
  id,
  disabled,
  variant = 'light',
}) => {
  const [fieldValue, setFieldValue] = useState('');
  const [placeholderColor, setPlaceholderColor] = useState();

  useEffect(() => {
    // Set color for select placeholder based on the CSS variant
    if (fieldValue === '') {
      switch (variant) {
        case 'c2c':
          setPlaceholderColor('white40');
          break;
        case 'light':
          setPlaceholderColor('black50');
          break;
        case 'dark':
          setPlaceholderColor('white40');
          break;
        case 'transparent':
          setPlaceholderColor('white');
          break;
        default:
          setPlaceholderColor('black50');
      }
    } else {
      switch (variant) {
        case 'c2c':
          setPlaceholderColor('white');
          break;
        case 'light':
          setPlaceholderColor('black');
          break;
        case 'dark':
          setPlaceholderColor('white');
          break;
        case 'transparent':
          setPlaceholderColor('white');
          break;
        default:
          setPlaceholderColor('black');
      }
    }
  }, [fieldValue]);

  const requiredValidation = {
    required: required ? 'Required' : false,
  };

  const emailValidation = {
    ...requiredValidation,
    pattern: {
      value: regex.email,
      message: 'Invalid',
    },
  };

  const validation =
    type === types.email ? emailValidation : requiredValidation;

  const inputId = `input-${id}`;
  const errorMessageId = `error-message-${id}`;

  let inputCss = variantCssLookup[variant]({ disabled, error });

  const inputBoxCss = variantInputBoxCssLookup[variant];
  let backgroundImageSelect = disabled
    ? `url("data:image/svg+xml,%3Csvg viewBox='0 0 60 119' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 0L0 47.0002H60L30 0Z'/%3E%3Cpath d='M30 119L0 72H60L30 119Z'/%3E%3C/svg%3E");`
    : 'none';
  if (variant === 'c2c' && !disabled)
    backgroundImageSelect = `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 11L12 15L8 11' stroke='%23464248' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`;
  // if (variant === 'c2c' && !disabled) backgroundImageSelect = `url("/static/assets/svg/c2c-chevron-left.svg")`;

  const select = (
    <select
      id={inputId}
      error={error}
      aria-invalid={isDefined(error)}
      aria-describedby={errorMessageId}
      name={name}
      ref={register(validation)}
      disabled={disabled}
      onChange={(event) => setFieldValue(event.target.value)}
      css={css({
        ...inputCss,
        backgroundRepeat: 'no-repeat, repeat',
        backgroundPosition: 'right 1em top 50%, 0 0',
        backgroundSize: `${variant === 'c2c' ? '27px' : '8px'} auto, 100%`,
        backgroundImage: backgroundImageSelect,
        color: placeholderColor,
      })}
    >
      {hasSelectPlaceholder && (
        <option value="" hidden={required}>
          {placeholder || 'Please choose an option'}
        </option>
      )}

      {options instanceof Array &&
        options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
    </select>
  );

  const input = (
    <input
      id={inputId}
      error={error}
      aria-invalid={isDefined(error)}
      aria-describedby={errorMessageId}
      name={name}
      placeholder={placeholder}
      type={type === types.date ? 'date' : 'text'}
      onChange={(event) => setFieldValue(event.target.value)}
      ref={register(validation)}
      disabled={disabled}
      css={css({
        ...inputCss,
        color: type === types.date ? placeholderColor : inputCss.color,
      })}
    />
  );

  const textArea = (
    <textarea
      id={inputId}
      error={error}
      aria-invalid={isDefined(error)}
      aria-describedby={errorMessageId}
      name={name}
      placeholder={placeholder}
      ref={register(validation)}
      disabled={disabled}
      css={css({
        ...inputCss,
        pt: 15,
      })}
    ></textarea>
  );

  let FormFieldComponent = null;

  switch (type) {
    case types.select:
      FormFieldComponent = select;
      break;
    case types.textarea:
      FormFieldComponent = textArea;
      break;
    case (types.email, types.text, type.date):
      FormFieldComponent = input;
      break;
    default:
      FormFieldComponent = input;
      break;
  }

  return (
    <Flex flexDirection="column" gy={2}>
      {variant !== 'transparent' && (
        <Text
          htmlFor={inputId}
          as={'label'}
          variant="caps"
          css={css({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            ...labelCss,
          })}
        >
          {label}
        </Text>
      )}
      <Box
        css={css({
          position: 'relative',
          ...inputBoxCss,
        })}
      >
        {FormFieldComponent}

        {error && (
          <Text
            variant="small"
            id={errorMessageId}
            css={css({
              position: 'absolute',
              top: '1px',
              bottom: '1px',
              right: '1px',
              display: 'flex',
              alignItems: 'center',
              color: 'red',
              bg: ({ colors, isDark }) =>
                `linear-gradient(to right, ${
                  isDark ? colors.blackAlpha[0] : colors.whiteAlpha[0]
                } 0%, ${isDark ? colors.black : colors.white} 20%)`,
              borderRadius: 1,
              pr: 3,
              pl: 4,
            })}
          >
            {error}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default FormField;

export const query = graphql`
  fragment FormFieldFragment on ContentfulFormField {
    id
    name
    label
    placeholder
    type
    required
    options
  }
`;

export const types = {
  text: 'Text',
  email: 'Email',
  date: 'Date',
  select: 'Dropdown',
  textarea: 'Textarea',
};
