import styled from 'styled-components';
import { Box } from 'src/components/FlexBox';

export default styled(Box)({
  position: 'relative',
  '> *': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
