import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ className }) => (
  <svg className={className} viewBox="0 0 26 18">
    <g fillRule="evenodd">
      <rect width="18" height="2" x="4" rx="1" />
      <rect width="18" height="2" x="4" y="16" rx="1" />
      <rect width="26" height="2" y="8" rx="1" />
    </g>
  </svg>
);

Hamburger.propTypes = {
  className: PropTypes.string,
};

export default Hamburger;
