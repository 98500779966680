import React from 'react';
import styled from 'styled-components';
import css from 'src/theme/css';

const Outer = styled.div`
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > *:first-child {
    position: relative;
  }
`;

const Frame = React.forwardRef(({ children, ratio, ...other }, ref) => {
  const paddingBottom = Array.isArray(ratio)
    ? ratio.map((r) => `calc(${r} * 100%)`)
    : `calc(${ratio} * 100%)`;
  return (
    <Outer {...other} ref={ref}>
      {ratio && (
        <div
          ratio={ratio}
          css={css({
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom,
          })}
        />
      )}
      {children}
    </Outer>
  );
});

export default Frame;
