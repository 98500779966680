import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import css from 'src/theme/css';
import { useStore } from 'src/components/GlobalState';
import { Box } from 'src/components/FlexBox';
import VideoPlayer from 'src/components/VideoPlayer';
import CalendlyEmbed from 'src/components/CalendlyEmbed';
import Button from 'src/components/Button';
import CloseIcon from 'src/assets/svg/Close';
import FrameContainer from 'src/components/FrameContainer';
import Navigation from './Navigation';
import BlurGate from './BlurGate';
import C2CSignUp from './C2CSignUp';
import C2CFullSignUp from './C2CFullSignUp';

const StyledCloseIcon = styled(CloseIcon)(
  ({ theme }) => css`
    width: 1rem;
    height: 1rem;
    margin-top: 0.5rem;

    fill: ${theme.color.white};
  `
);

export default ({ nav, children }) => {
  const body = useRef();
  const top = useRef();
  const container = useRef();
  const modal = useStore((state) => state.modal);
  const closeModal = useStore((state) => state.closeModal);

  const elementLookup = {
    video: (
      <FrameContainer
        ratio={9 / 16}
        style={{ width: '100%', alignSelf: 'stretch' }}
      >
        <VideoPlayer url={modal.data} autoplay ref={container} />
      </FrameContainer>
    ),
    calendly: <CalendlyEmbed ref={container} />,
    nav: <Navigation ref={container} {...nav} />,
    blurGate: <BlurGate ref={container} />,
    c2cSignUp: <C2CSignUp ref={container} />,
    c2cFullSignUp: <C2CFullSignUp ref={container} email={modal.data} />,
  };

  const isLocked = modal.isOpen && modal.type === 'blurGate';
  const isC2CModal =
    modal.type === 'c2cSignUp' || modal.type === 'c2cFullSignUp';
  const currentTop = typeof window !== 'undefined' && window.pageYOffset;

  useEffect(() => {
    if (body.current) {
      if (modal.isOpen) {
        top.current = currentTop;
        body.current.style.transform = `translate3d(0, ${-top.current}px, 0)`;
      } else if (top.current) {
        body.current.style.transform = '';
        window.scrollTo(0, top.current);
        top.current = undefined;
      }
    }
  }, [isC2CModal, modal, currentTop]);

  useEffect(() => {
    if (modal.isOpen) {
      const onKeyPress = (e) => {
        if (modal.type === 'blurGate') {
          return;
        }

        if (e.key === 'Escape') {
          closeModal();
        }
      };

      document.addEventListener('keydown', onKeyPress);

      return () => {
        document.removeEventListener('keydown', onKeyPress);
      };
    }
  }, [modal, closeModal]);

  useEffect(() => {
    if (modal.isOpen) {
      const handleClickOutside = (e) => {
        if (modal.type === 'blurGate') {
          return;
        }

        if (container.current && !container.current.contains(e.target)) {
          closeModal();
        }
      };

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [container, modal, closeModal]);

  // Don't want to break anything so I'm doing a special style only for C2C modals
  const defaultModalCSS = {
    position: 'relative',
    px: 'pageMargin',
    py: 'sectionMargin',
    minHeight: '100vh',
  };

  const c2cModalCSS = {
    display: 'block',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    overflow: 'scroll',
  };

  return (
    <>
      <div
        ref={body}
        style={{ width: '100%', position: modal.isOpen ? 'fixed' : 'relative' }}
      >
        {children}
      </div>
      {modal.isOpen && (
        <>
          <div
            css={css({
              pointerEvents: 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'flex-end',
              background: `linear-gradient(to bottom, rgba(0, 0, 0, ${
                isC2CModal ? '0.9' : '0.75'
              }), black)`,
              '-webkit-backdrop-filter': 'blur(10px)',
            })}
          />
          <Box
            alignItems="center"
            justifyContent="center"
            css={css(isC2CModal ? c2cModalCSS : defaultModalCSS)}
          >
            {modal.isOpen && elementLookup[modal.type]}
          </Box>
          {!isLocked && (
            <Button
              aria-label="Close popup"
              onClick={closeModal}
              css={css({
                position: 'fixed',
                top: 0,
                right: 'var(--page-margin)',
                width: 'var(--page-margin)',
                height: 'var(--header-height)',
                fill: 'white',
              })}
            >
              <StyledCloseIcon />
            </Button>
          )}
        </>
      )}
    </>
  );
};
