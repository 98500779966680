import React from 'react';
import PropTypes from 'prop-types';

const Twitter = ({ className }) => (
  <svg className={className} viewBox="0 0 18 14">
    <path
      fillRule="evenodd"
      d="M18 1.663a7.655 7.655 0 0 1-2.124.546A3.555 3.555 0 0 0 17.497.262c-.708.405-1.507.7-2.34.854A3.758 3.758 0 0 0 12.46 0c-2.045 0-3.69 1.586-3.69 3.533 0 .273.035.547.092.81-3.061-.154-5.79-1.554-7.607-3.698a3.394 3.394 0 0 0-.502 1.783c0 1.225.65 2.308 1.644 2.942a3.84 3.84 0 0 1-1.667-.448v.044c0 1.717 1.268 3.139 2.958 3.467-.308.076-.64.12-.97.12a4.85 4.85 0 0 1-.697-.055c.468 1.4 1.827 2.418 3.449 2.45a7.598 7.598 0 0 1-4.58 1.51c-.309 0-.594-.011-.891-.044A10.762 10.762 0 0 0 5.665 14c6.784 0 10.496-5.381 10.496-10.052 0-.153 0-.306-.011-.459A7.757 7.757 0 0 0 18 1.663z"
    />
  </svg>
);

Twitter.propTypes = {
  className: PropTypes.string,
};

export default Twitter;
