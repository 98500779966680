import React, { forwardRef } from 'react';
import css from 'src/theme/css';

import Layers from 'src/components/Layers';
import { Box } from 'src/components/FlexBox';
import ColorProvider from 'src/components/ColorProvider';
import ImageGroup from 'src/components/ImageGroup';

const ModuleWrapper = (
  {
    isHero,
    isViewportHeight,
    background,
    hasScrim,
    children,
    colorMode,
    elementId,
    backgroundLayers = [],
    ...other
  },
  ref
) => (
  <ColorProvider mode={colorMode ? colorMode.toLowerCase() : 'dark'}>
    <Layers
      id={elementId}
      css={css({
        mt: ({ space }) => (isHero ? `calc(${space.headerHeight} * -1)` : 0),
        background: colorMode
          ? colorMode === 'dark'
            ? 'black'
            : 'white'
          : 'none',
      })}
    >
      <ImageGroup {...background} />
      {backgroundLayers}
      <Box
        css={css({
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: isViewportHeight ? '100vh' : '0px',
          pt: ({ space }) => (isHero ? space.headerHeight : 0),
          background: ({ colors, mode }) =>
            hasScrim &&
            `linear-gradient(
            to ${mode === 'dark' ? 'bottom' : 'top'},
            ${colors.antiModeAlpha[2]},
            ${colors.antiModeAlpha[8]})
            `,
        })}
        style={{ position: 'relative' }}
      >
        <Box
          css={css({
            width: '100%',
            maxWidth: 'pageMaxWidth',
            overflow: 'hidden',
          })}
          flexDirection="column"
          {...other}
          ref={ref}
        >
          {children}
        </Box>
      </Box>
    </Layers>
  </ColorProvider>
);

export default forwardRef(ModuleWrapper);
