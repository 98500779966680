import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import Footer from 'src/components/Footer';
import SubFooter from 'src/components/SubFooter';
import Navigation from 'src/components/Navigation';
import Modal from 'src/components/Modal';
import TopBanner from 'src/components/TopBanner';
import ColorProvider from 'src/components/ColorProvider';
import PageMeta from 'src/components/PageMeta';
import { useStaticQuery, graphql } from 'gatsby';
import { useStore } from 'src/components/GlobalState';
import ConsentBanner from 'src/components/ConsentBanner';
import StickyBanner from 'src/components/StickyBanner';

const Layout = ({
  children,
  isNavTransparent,
  removeLayout,
  removeSubFooter,
  showNav,
  nav,
  isNavSticky,
  stickyBannerForm,
}) => {
  const setReflow = useStore((state) => state.setReflow);
  const cookies = useStore((state) => state.cookies);
  let fromFalkorABTest = false;
  if (cookies && typeof cookies['FIO_DINOSAUR_REWRITE'] !== 'undefined') {
    fromFalkorABTest = cookies['FIO_DINOSAUR_REWRITE'] === 'true';
  }

  useEffect(() => {
    setReflow();
  }, [setReflow]);

  const { defaultMeta, defaultNav } = useStaticQuery(graphql`
    query {
      defaultMeta: contentfulPageMeta(slug: { eq: "global#default-meta" }) {
        title
        ...PageMetaFragment
      }
      defaultNav: contentfulNav(slug: { eq: "default-nav" }) {
        ...NavFragment
      }
    }
  `);

  return (
    <ColorProvider mode="dark">
      <Helmet defaultTitle={defaultMeta.title} titleTemplate="Frame.io | %s">
        <html lang="en" />
      </Helmet>
      <PageMeta {...defaultMeta} />
      <TopBanner />
      <ConsentBanner />
      {stickyBannerForm && <StickyBanner form={stickyBannerForm} />}
      <Modal nav={nav || defaultNav}>
        {!removeLayout && (
          <Navigation
            isTransparent={isNavTransparent}
            show={showNav}
            isSticky={isNavSticky}
            {...(nav || defaultNav)}
          />
        )}
        {children}
        {!removeLayout && fromFalkorABTest && <Footer />}
        {!removeSubFooter && <SubFooter />}
      </Modal>
    </ColorProvider>
  );
};

export default Layout;
