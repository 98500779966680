import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ className }) => (
  <svg className={className} viewBox="0 0 10 10">
    <path d="M5 0a5 5 0 1 0 0 10A5 5 0 0 0 5 0zm.01 7.485s-.203.153-.342.152l-.02.01v-.01a.398.398 0 0 1-.087-.015l-.044-.012c-.243-.068-.371-.339-.31-.612l.337-1.536.152-.693c.142-.644-.45.136-.57-.16-.08-.196.46-.608.854-.918 0 0 .203-.152.342-.151l.02-.01v.01c.029.001.058.006.088.014l.044.012c.242.068.39.345.33.618L5.466 5.72l-.153.694c-.141.643.44-.14.562.157.08.195-.47.604-.865.914zm.855-4.402A.588.588 0 1 1 4.723 2.8a.588.588 0 0 1 1.142.283z" />
  </svg>
);

Tooltip.propTypes = {
  className: PropTypes.string,
};

export default Tooltip;
