import React from 'react';

export default ({ id, autoplay, ...other }) => {
  return (
    <iframe
      title="youtube player"
      src={`https://www.youtube.com/embed/${id}?rel=0&autoplay=${
        autoplay ? 1 : 0
      }`}
      frameBorder="0"
      allow={`accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture`}
      allowFullScreen
      {...other}
    />
  );
};
