import React, { useState } from 'react';
import css from 'src/theme/css';
import { Box, Flex } from 'src/components/FlexBox';
import SmartButton from 'src/components/SmartButton';
import Button from 'src/components/Button';
import Form from 'src/components/Form';
import ColorProvider from 'src/components/ColorProvider';
import CloseIcon from 'src/assets/svg/Close';

export default ({ form }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ColorProvider mode="light">
      <Box
        flexDirection="column"
        css={css({
          display: ['flex', 'none'],
          position: 'fixed',
          px: 'pageMargin',
          py: 3,
          bottom: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
          bg: 'white',
        })}
      >
        <SmartButton
          variant="Primary"
          label={form.submitButtonText}
          onClick={() => setIsOpen(true)}
        />
      </Box>
      <Flex
        gy={4}
        flexDirection="column"
        css={css({
          position: 'fixed',
          px: 'pageMargin',
          py: 4,
          bottom: 0,
          left: 0,
          width: '100%',
          borderTopLeftRadius: 1,
          borderTopRightRadius: 1,
          boxShadow: 'topShadow.xl',
          bg: 'white',
          zIndex: 1,
          transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
          transitionProperty: 'transform',
          transitionDuration: '500ms',
          transitionTimingFunction: ({ ease }) => ease.inOutCubic,
        })}
      >
        <Button onClick={() => setIsOpen(false)} css={{ alignSelf: 'end' }}>
          <CloseIcon css={css({ size: 3 })} />
        </Button>
        <Form {...form} />
      </Flex>
    </ColorProvider>
  );
};
