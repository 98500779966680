import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ className }) => (
  <svg className={className} viewBox="0 0 17 17">
    <path
      fillRule="evenodd"
      d="M16.06 0H.94A.94.94 0 0 0 0 .94v15.12c0 .52.42.94.94.94h8.136v-6.585H6.862V7.847h2.214V5.954c0-2.191 1.35-3.386 3.309-3.386.93 0 1.737.066 1.97.1v2.29l-1.35.011c-1.074 0-1.273.498-1.273 1.24v1.638h2.534l-.332 2.568h-2.202V17h4.327c.52 0 .941-.42.941-.94V.94a.94.94 0 0 0-.94-.94z"
    />
  </svg>
);

Facebook.propTypes = {
  className: PropTypes.string,
};

export default Facebook;
