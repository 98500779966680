import React from 'react';
import PropTypes from 'prop-types';

const Checkmark = ({ className }) => (
  <svg className={className} width="18" height="14">
    <path d="M17.156 2.906L6.531 13.531.438 7.281l2.187-2.187L6.531 9 14.97.562z" />
  </svg>
);

Checkmark.propTypes = {
  className: PropTypes.string,
};

export default Checkmark;
