import React, { useRef, useEffect } from 'react';
import { isDefined } from 'typical';
import { useStore } from 'src/components/GlobalState';
import { Flex } from 'src/components/FlexBox';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import Link from 'src/components/Link';
import ColorProvider from 'src/components/ColorProvider';
import css from 'src/theme/css';
import CloseIcon from 'src/assets/svg/Close';

const useClickOutside = (callback) => {
  const ref = useRef();
  useEffect(() => {
    const listener = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  });
  return ref;
};

export default () => {
  const cookies = useStore((state) => state.cookies);
  const setCookie = useStore((state) => state.setCookie);

  const hasSetPreferences =
    cookies && isDefined(cookies['tracking-preferences']);
  const hasConsent = cookies && isDefined(cookies['fio-marketing-consent']);
  const isVisible = !hasSetPreferences && !hasConsent;

  const close = () => {
    setCookie('fio-marketing-consent', true);
  };

  const ref = useClickOutside(close);

  return isVisible ? (
    <ColorProvider mode="light">
      <Flex
        ref={ref}
        gx={2}
        alignItems="flex-start"
        css={css({
          bg: 'antiMode',
          position: 'fixed',
          bottom: 0,
          left: 0,
          margin: 3,
          zIndex: 1,
          maxWidth: '35rem',
          borderRadius: 1,
          border: 'solid 1px',
          borderColor: 'gray200',
          boxShadow: 'shadow.m',
          p: '1.25rem',
        })}
      >
        <Flex flexDirection="column" alignItems="flex-start" gy={2}>
          <Text variant="small" css={css({ fontSize: ['1rem', '0.875rem'] })}>
            We use cookies (and other similar technologies) to collect data to
            improve your experience on our site. By using our website, you’re
            agreeing to the collection of data as described in our{' '}
            <Link
              url="/privacy"
              css={css({ color: 'purpleLight' })}
              style={{ textDecoration: 'underline' }}
            >
              Privacy Policy
            </Link>
            .
          </Text>
        </Flex>
        <Button onClick={close} css={css({ flexShrink: 0 })}>
          <CloseIcon css={css({ size: '0.75rem' })} />
        </Button>
      </Flex>
    </ColorProvider>
  ) : (
    <></>
  );
};
