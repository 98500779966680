import React, { forwardRef } from 'react';
import globalTheme from 'src/theme';
import { useStore } from 'src/components/GlobalState';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import Stack from 'src/components/Stack';

export default forwardRef(
  ({ linkList, signInLink, signUpLink, goToAppLink }, ref) => {
    const closeModal = useStore((state) => state.closeModal);
    const isLoggedIn = useStore((state) => state.isLoggedIn);

    return (
      <Stack
        as="nav"
        ref={ref}
        justification="center"
        css={`
          height: 100%;
          align-self: center;
        `}
      >
        {linkList &&
          linkList.contentNodes.map((link) => (
            <SmartButton
              action={smartButton.actions.link}
              variant="Caps"
              key={link.id}
              url={link.url}
              label={link.label}
              onClick={closeModal}
              css={`
                padding: 1rem 0;
                border-bottom: solid 1px ${globalTheme.color.white10};

                div {
                  border: 0;
                }
              `}
            />
          ))}
        {isLoggedIn ? (
          <>
            <SmartButton
              variant={smartButton.variants.primary}
              action={smartButton.actions.goToApp}
              label={goToAppLink.label}
              url={goToAppLink.url}
              css={`
                margin-top: 1rem;
              `}
            />
          </>
        ) : (
          <>
            <SmartButton
              action={smartButton.actions.signIn}
              variant="Caps"
              label={signInLink.label}
              url={signInLink.url}
              css={`
                padding: 1rem 0;
                margin-top: 1rem;
              `}
            />
            <SmartButton
              action={smartButton.actions.signUp}
              variant={smartButton.variants.primary}
              label={signUpLink.label}
            />
          </>
        )}
      </Stack>
    );
  }
);
