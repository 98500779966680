import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import css from 'src/theme/css';
import { isDefined } from 'typical';
import Text from 'src/components/Text';
import ErrorExclamationIcon from 'src/assets/svg/ErrorExclamation';
import CheckmarkIcon from 'src/assets/svg/Checkmark';

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const buttonCss = {
  borderRadius: 1,
  p: 3,
  bg: 'purpleLight',
  color: 'white',
  fontSize: 0,
  height: '3.2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'backgroundColor 200ms, color 200ms, filter 200ms',
};

const defaultCss = {
  ...buttonCss,
  ':hover': {
    filter: 'brightness(80%)',
  },
};

const successCss = {
  ...buttonCss,
  bg: 'lightSuccess',
  color: 'success',
};

const failureCss = {
  ...buttonCss,
  bg: 'lightError',
  color: 'error',
  ':hover': {
    filter: 'brightness(80%)',
  },
};

const LoadingIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border: solid 0.15em;
  border-radius: 50%;

  animation: 0.5s ${pulseAnimation} infinite alternate
    cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;

const SuccessIcon = styled(CheckmarkIcon)`
  width: 1.2rem;
  height: 1rem;
  fill: currentColor;
`;

const FailureIcon = styled(ErrorExclamationIcon)`
  width: 1.2rem;
  height: 1.2rem;
  fill: currentColor;
`;

const iconLookup = {
  success: SuccessIcon,
  failure: FailureIcon,
  loading: LoadingIcon,
};

const SubmitButton = ({ label, state, ...other }) => {
  const Icon = iconLookup[state];

  return (
    <button
      type="submit"
      state={state}
      aria-label={label}
      css={css(
        state === 'success'
          ? successCss
          : state === 'failure'
          ? failureCss
          : defaultCss
      )}
      {...other}
    >
      <Text
        variant="caps"
        css={{ color: 'inherit', marginRight: '0.5rem', lineHeight: 1 }}
      >
        {label}
      </Text>

      {isDefined(Icon) && <Icon />}
    </button>
  );
};

SubmitButton.defaultProps = {
  label: 'Submit',
};

SubmitButton.propTypes = {
  label: PropTypes.string,
  state: PropTypes.string,
};

export default SubmitButton;
