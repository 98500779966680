import React from 'react';
import PropTypes from 'prop-types';

const FrameLogo = ({ className }) => (
  <svg
    width="30"
    height="34"
    viewBox="0 0 30 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.94386 34C0.556347 34 -0.363125 32.5519 0.138629 31.2621C1.85936 26.8389 2.80321 22.0293 2.80321 17C2.80321 11.9707 1.85936 7.16112 0.138628 2.73785C-0.363126 1.44806 0.556346 0 1.94386 0C2.69903 0 3.38494 0.447721 3.6615 1.14835C5.59933 6.05752 6.66367 11.4048 6.66367 17C6.66367 22.5952 5.59933 27.9425 3.6615 32.8517C3.38494 33.5523 2.69903 34 1.94386 34Z"
      fill="#FCFCFC"
    />
    <path
      d="M11.2007 30.1509C9.92959 30.1509 9.01381 28.9348 9.30112 27.7003C10.1012 24.2625 10.5241 20.6805 10.5241 17C10.5241 13.3195 10.1012 9.73754 9.30112 6.29973C9.01381 5.06517 9.92959 3.84906 11.2007 3.84906C12.0658 3.84906 12.8264 4.42828 13.0246 5.26785C13.914 9.03462 14.3846 12.9625 14.3846 17C14.3846 21.0375 13.914 24.9654 13.0246 28.7322C12.8264 29.5717 12.0658 30.1509 11.2007 30.1509Z"
      fill="#FCFCFC"
    />
    <path
      d="M19.9491 24.3774C18.786 24.3774 17.8921 23.3553 18.001 22.2007C18.1625 20.4888 18.245 18.754 18.245 17C18.245 15.246 18.1625 13.5112 18.001 11.7993C17.8921 10.6447 18.786 9.62264 19.9491 9.62264C20.9181 9.62264 21.7389 10.3415 21.8318 11.3031C22.0128 13.1777 22.1055 15.0781 22.1055 17C22.1055 18.9219 22.0128 20.8223 21.8318 22.6969C21.7389 23.6585 20.9181 24.3774 19.9491 24.3774Z"
      fill="#FCFCFC"
    />
    <path
      d="M27.841 14.5393L28.9441 15.6391C29.6979 16.3907 29.6979 17.6093 28.9441 18.3608L27.841 19.4607C27.0303 20.2689 25.6442 19.6965 25.6442 18.5535V15.4465C25.6442 14.3035 27.0303 13.731 27.841 14.5393Z"
      fill="#FCFCFC"
    />
  </svg>
);

FrameLogo.propTypes = {
  className: PropTypes.string,
};

export default FrameLogo;
