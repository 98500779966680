import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from "gatsby"

import { useStore } from 'src/components/GlobalState';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import SubmitButton from 'src/components/SubmitButton';

export default () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "images/c2c/icon_check.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    const closeModal = useStore((state) => state.closeModal);

    return (
        <div css={css({
            m: 0,
            px: [0, 20, null],
            textAlign: 'center',
            width: [270, 330, null]
        })}>
            <div css={css({
                m: '0 auto 20px auto',
                width: 75
            })}>
                <Img fluid={data.file.childImageSharp.fluid} alt="" />
            </div>
            <Text variant="aktivLarge" css={css({
                m: '0 0 8px 0',
            })}>You're all set.</Text>
            <Text variant="aktiv">We'll get your info into our beta system and update you soon.</Text>
            <SubmitButton
                onClick={closeModal}
                state={'initial'}
                label={'Done'}
                css={css({
                    cursor: 'pointer',
                    width: '100%',
                    mt: 20,
                    color: 'black',
                    backgroundColor: 'white'
                    // ...(buttonCss || {}),
                })}
            />
        </div>
    )
}