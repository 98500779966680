import React from 'react';
import css from 'src/theme/css';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Text from 'src/components/Text';
import { Flex } from 'src/components/FlexBox';

const AnnouncementBanner = ({ descriptor, richText }) => {
  return (
    <Flex gx={3} alignItems="center">
      <Text
        variant="caps"
        css={css({
          textTransform: 'uppercase',
          bg: 'green',
          color: 'black',
          px: 3,
          py: 1,
          borderRadius: '100vw',
        })}
      >
        {descriptor}
      </Text>
      <Text variant="small">{documentToReactComponents(richText)}</Text>
    </Flex>
  );
};

AnnouncementBanner.defaultProps = {
  descriptor: 'New',
};

export default AnnouncementBanner;

export const query = graphql`
  fragment AnnouncementBannerFragment on ContentfulAnnouncementBanner {
    descriptor
    richText {
      json
    }
  }
`;
