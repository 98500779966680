import React, { useState, forwardRef } from 'react';

import css from 'src/theme/css';
import Text from 'src/components/Text';
import Layers from 'src/components/Layers';
import { Box } from 'src/components/FlexBox';
import { trackSubmit } from 'src/utils/analytics';

import Done from './Done';
import Form from './Form';

import { encode } from 'src/components/Modal/utils';
import formData from './data';

export default forwardRef(({ email }, ref) => {
  const [disabled, setDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = (data) => {
    if (disabled) return;
    if (isSubmitted) return;

    setDisabled(true);

    // submit to netlify
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(
        { 'form-name': formData.slug, email, ...data },
        window.encodeURIComponent
      ),
    })
      .then(() => {
        // now show last step
        setIsSubmitted(true);
      })
      .catch((error) => console.error(error));

    trackSubmit(data);
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      css={css({
        position: 'relative',
        px: 'pageMargin',
        py: ['sectionMargin', null, '10px'],
        minHeight: '100vh',
      })}
    >
      <Layers ref={ref}>
        <Box
          css={css({
            bg: 'c2cGlow',
            backgroundSize: 'cover',
            filter: 'blur(3rem)',
            opacity: 0.25,
          })}
        />
        <Box
          flexDirection="column"
          css={css({
            position: 'relative',
            bg: 'black',
            borderRadius: 1,
            p: 4,
          })}
        >
          {isSubmitted ? (
            <Done />
          ) : (
            <>
              <Text variant="aktivLarge">
                Thank you for applying to the C2C beta program.
              </Text>
              <Text variant="aktiv" css={css({ pt: 2 })}>
                Tell us a bit more about youself, and your project.
              </Text>

              <Form
                submit={submit}
                disabled={disabled}
                css={css({ pt: '1.5rem', margin: 0 })}
              />
            </>
          )}
        </Box>
      </Layers>
    </Box>
  );
});
