import React from 'react';
import PropTypes from 'prop-types';

const ChevronRight = ({ className }) => (
  <svg className={className} viewBox="0 0 10 15">
    <path d="M.705 13.381l1.414 1.414L9.414 7.5 2.119.205.705 1.619 6.586 7.5z" />
  </svg>
);

ChevronRight.propTypes = {
  className: PropTypes.string,
};

export default ChevronRight;
