import { useState, useEffect } from 'react';

export default query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    setMatches(media.matches);

    const handleChange = event => setMatches(event.matches);
    media.addListener(handleChange);

    return () => {
      media.removeListener(handleChange);
    };
  }, [query]);

  return matches;
};
