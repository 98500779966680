import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex, Grid } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';
import FacebookLogo from 'src/assets/svg/Facebook';
import TwitterLogo from 'src/assets/svg/Twitter';
import DribbbleLogo from 'src/assets/svg/Dribbble';
import Text from 'src/components/Text';
import Link from 'src/components/Link';
import css from 'src/theme/css';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/frame-logo-gradient.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <ModuleWrapper colorMode="dark" css={css({ py: 4, px: 'pageMargin' })}>
      <Grid
        gridTemplateColumns={['auto', '1fr auto 1fr']}
        gridTemplateRows={['auto auto auto', 'auto']}
        gridGap={4}
        alignItems="center"
        justifyItems="center"
        gridAutoFlow="column dense"
      >
        <Flex
          justifySelf={[null, 'end']}
          gx={4}
          alignItems="center"
          gridColumn={[null, '3']}
        >
          <Link aria-label="Facebook" url="https://www.facebook.com/frameioapp">
            <FacebookLogo css={css({ size: '1.5rem', fill: 'gray400' })} />
          </Link>
          <Link aria-label="Twitter" url="https://twitter.com/Frame_io">
            <TwitterLogo css={css({ size: '1.5rem', fill: 'gray400' })} />
          </Link>
          <Link aria-label="Dribbble" url="https://dribbble.com/frameio">
            <DribbbleLogo css={css({ size: '1.5rem', fill: 'gray400' })} />
          </Link>
        </Flex>
        <Flex justifySelf={[null, 'start']} gx={3} gridColumn={[null, '1']}>
          <Link url="/">
            <Text variant="small" color="gray400">
              © {new Date().getFullYear()} Adobe. All rights reserved.
            </Text>
          </Link>
          <Link url="/terms">
            <Text variant="small" color="gray400">
              Terms
            </Text>
          </Link>
          <Link url="/privacy">
            <Text variant="small" color="gray400">
              Privacy
            </Text>
          </Link>
        </Flex>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Frame.io"
          css={css({ width: '163px', height: '50px' })}
        />
      </Grid>
    </ModuleWrapper>
  );
};
