import styled from 'styled-components';
import { color, typography, variant } from 'styled-system';
import css from 'src/theme/css';

export const heading = {
  fontWeight: 'medium',
  lineHeight: 'heading',
  color: 'mode',
  fontFeatureSettings: `"liga"`,
  '> b, > strong': {
    fontWeight: 'inherit',
    background:
      'linear-gradient(135deg, rgba(47, 196, 209, 1) 0%, rgba(79, 84, 241, 1) 28%, rgba(200, 109, 215, 1) 100%)',
    display: 'inline',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '> i, > em': {
    fontStyle: 'inherit',
    color: 'purpleLight',
  },
};

export default styled.div(
  css({
    fontFamily: 'avenir',
    fontWeight: 'medium',
    fontSize: 1,
    color: 'modeAlpha.7',
    lineHeight: 'paragraph',
    '> b, > strong': {
      fontWeight: 'demibold',
      color: 'mode',
    },
  }),
  variant({
    variants: {
      small: {
        fontSize: 0,
      },
      caps: {
        fontSize: 0,
        fontFamily: 'aktiv',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'caps',
        color: 'mode',
      },
      aktiv: {
        fontFamily: 'aktiv',
        lineHeight: 1.4,
      },
      aktivLarge: {
        fontFamily: 'aktiv',
        lineHeight: 1.3,
        fontSize: '1.5rem',
        color: 'mode',
        letterSpacing: '-0.03em',
      },
      aktivTitle: {
        fontFamily: 'aktiv',
        lineHeight: 1.3,
        fontSize: ['2.666rem', null, '2.9rem', '3.4rem', '3.625rem'],
        color: 'mode',
        letterSpacing: '-0.03em',
      },
      heading: {
        xs: {
          ...heading,
          fontSize: 1,
          fontWeight: 'demibold',
          lineHeight: 1.2,
        },
        s: {
          ...heading,
          fontSize: [1, 2],
          fontWeight: 'demibold',
          lineHeight: 1.2,
        },
        m: {
          ...heading,
          fontSize: [2, 3],
          fontWeight: 'demibold',
          lineHeight: 1.2,
        },
        l: {
          ...heading,
          fontFamily: 'aktiv',
          fontSize: [3, 4, 5],
          letterSpacing: '-0.03em',
          lineHeight: 1.1,
        },
        xl: {
          ...heading,
          fontFamily: 'aktiv',
          fontSize: [4, 5, 6],
          letterSpacing: '-0.035em',
        },
        xxl: {
          ...heading,
          fontFamily: 'aktiv',
          fontSize: [5, 6, 7],
          letterSpacing: '-0.04em',
        },
        xxxl: {
          ...heading,
          fontFamily: 'aktiv',
          fontSize: [6, 7, 8],
          letterSpacing: '-0.045em',
        },
      },
    },
  }),
  color,
  typography
);
