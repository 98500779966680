import React, { useRef, useEffect } from 'react';

export default ({ id, autoplay, ...other }) => {
  const wrapper = useRef(null);

  useEffect(() => {
    if (wrapper.current) {
      const calendlyScript = document.createElement('script');
      calendlyScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wrapper.current.appendChild(calendlyScript);
    }
  }, [wrapper]);

  return (
    <div ref={wrapper}>
      <div
        {...other}
        css={{ width: '100%', height: '100%' }}
        className={`wistia_embed wistia_async_${id} autoPlay=${autoplay}`}
      />
    </div>
  );
};
