import React, { useState, useEffect, memo } from 'react';
import css from 'src/theme/css';
import { useStore } from 'src/components/GlobalState';
import SmartButton, { actions, variants } from 'src/components/SmartButton';
import Cluster from 'src/components/Cluster';
import ColorProvider from 'src/components/ColorProvider';
import { useBreakpoint } from '../../Breakpoints';

import {
  Wrapper,
  Inner,
  NavItems,
  HamburgerBtn,
  HamburgerIcon,
  Wordmark,
  Logo,
  LogoLink,
  SignUpIcon,
} from './style';

export default memo(
  ({
    linkList,
    signUpLink,
    signInLink,
    goToAppLink,
    isTransparencyEnabled,
    show = true,
    isSticky = true,
    variant,
  }) => {
    const isDesktop = useBreakpoint(2);
    const modal = useStore((state) => state.modal);
    const [isTransparent, setIsTransparent] = useState(true);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(
      () =>
        useStore.subscribe(
          (scroll) => {
            setIsTransparent(scroll.y <= 0);
            setIsVisible(scroll.y <= 100 || scroll.dy < 0);
          },
          (state) => state.scroll
        ),
      [setIsTransparent, setIsVisible]
    );

    return (
      <Wrapper
        isTransparent={isTransparent && isTransparencyEnabled}
        isVisible={show && isVisible && !modal.isOpen}
        isSticky={isSticky}
      >
        <Inner
          css={css({
            maxWidth: 'pageMaxWidth',
            px: 'navMargin',
            margin: 'auto',
          })}
        >
          <NavItems hasBorder={isTransparent}>
            {isDesktop ? (
              <DesktopNavItems
                linkList={linkList}
                signUpLink={signUpLink}
                signInLink={signInLink}
                goToAppLink={goToAppLink}
                variant={variant}
              />
            ) : (
              <MobileNavItems />
            )}
          </NavItems>
        </Inner>
      </Wrapper>
    );
  }
);

const MobileNavItems = () => {
  const openModal = useStore((state) => state.openModal);
  return (
    <>
      <LogoLink href="/">
        <Logo />
      </LogoLink>
      <HamburgerBtn
        aria-label="Open navigation menu"
        onClick={() => openModal('nav')}
      >
        <HamburgerIcon />
      </HamburgerBtn>
    </>
  );
};

const DesktopNavItems = ({
  linkList,
  signUpLink,
  signInLink,
  goToAppLink,
  variant,
}) => {
  const cookies = useStore((state) => state.cookies);
  let fromFalkorABTest = false;
  if (cookies && typeof cookies['FIO_DINOSAUR_REWRITE'] !== 'undefined') {
    fromFalkorABTest = cookies['FIO_DINOSAUR_REWRITE'] === 'true';
  }
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const isDesktopWordmark = useBreakpoint(3);
  return (
    <ColorProvider mode="dark">
      <Cluster spacing="40px">
        <LogoLink href="/">
          <Logo />
        </LogoLink>
        {linkList &&
          fromFalkorABTest &&
          linkList.contentNodes.map((link) => (
            <SmartButton
              action={actions.link}
              variant={variants.caps}
              key={link.id}
              url={link.url}
              label={link.label}
            />
          ))}
      </Cluster>
      <Cluster spacing="40px">
        {isLoggedIn ? (
          <>
            <SmartButton
              action={actions.goToApp}
              variant={variants.primary}
              action={actions.goToApp}
              label={goToAppLink.label}
              url={goToAppLink.url}
              trackingData={{
                position: 'center',
              }}
            />
          </>
        ) : (
          <>
            <SmartButton
              action={actions.signIn}
              variant={variants.simple}
              label={signInLink.label}
              url={signInLink.url}
              trackingData={{
                position: 'left',
              }}
            />
            <SmartButton
              action={actions.signUp}
              variant={variants.dinosaurPrimary}
              label={signUpLink.label}
              trackingData={{
                position: 'right',
              }}
            >
              <SignUpIcon />
            </SmartButton>
          </>
        )}
      </Cluster>
    </ColorProvider>
  );
};
