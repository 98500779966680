import React from 'react';
import Link from 'src/components/Link';
import { Wrapper, TriggerBtn, TooltipIcon, Text } from './style';
import OptionalWrapper from 'src/components/OptionalWrapper';

const Tooltip = ({ text, show, children, hasIcon, url, ...other }) => (
  <OptionalWrapper Component={url && Link} url={url}>
    <Wrapper>
      <TriggerBtn {...other}>
        {children && <div css={{ flexGrow: 1 }}>{children}</div>}
        {hasIcon && <TooltipIcon />}
        {show && <Text>{text}</Text>}
      </TriggerBtn>
    </Wrapper>
  </OptionalWrapper>
);

Tooltip.defaultProps = {
  show: true,
  hasIcon: true,
};

export default Tooltip;
