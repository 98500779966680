import React from 'react';
import css from 'src/theme/css';
import Layers from 'src/components/Layers';
import AppStoreIcon from 'src/assets/svg/AppStoreBadge';
import CircleIcon, * as circleIcon from 'src/components/CircleIcon';
import Text from 'src/components/Text';
import AppleLogo from 'src/assets/svg/logos/apple-logo.inline.svg';
import WindowsLogo from 'src/assets/svg/logos/windows-logo.inline.svg';
import C2CPlayIcon from 'src/assets/svg/c2c-play-large.inline.svg';
import C2CPlayCircle from 'src/assets/svg/c2c-play-large-circle.inline.svg';
import { Box } from 'src/components/FlexBox';

const fill = {
  bg: 'purpleLight',
  color: 'white',
  fill: 'white',
  borderRadius: 1,
  lineHeight: 1,
  height: '3.2rem',
  p: 3,
};

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '> * + *': {
    ml: 2,
  },
};

const hover = {
  transition: 'filter 0.2s',
  ':hover': {
    filter: 'brightness(80%)',
  },
};

export const DinosaurPrimary = ({ label, children, ...other }) => (
  <Text
    css={css({
      ...fill,
      ...flex,
      ...hover,
      borderRadius: 100,
      padding: '14px 16px 13px',
    })}
    {...other}
  >
    <span>{label}</span>
    {children}
  </Text>
);

export const Simple = ({ label, ...other }) => (
  <Text
    css={css({
      transition: 'color 0.2s',
      ':hover': {
        color: 'modeAlpha.5',
      },
    })}
    {...other}
  >
    {label}
  </Text>
);

export const Primary = ({ label, children, ...other }) => (
  <Text
    variant="caps"
    css={css({
      ...fill,
      ...flex,
      ...hover,
    })}
    {...other}
  >
    <span>{label}</span>
    {children}
  </Text>
);

export const Secondary = (props) => (
  <Primary
    {...props}
    css={css({
      bg: 'white',
      color: 'purpleLight',
      fill: 'purpleLight',
    })}
  />
);

export const Knockout = ({ label, children, ...other }) => (
  <Text
    variant="caps"
    css={css({
      ...fill,
      ...flex,
      border: 'solid 2px',
      borderColor: 'mode',
      fill: 'currentcolor',
      color: 'mode',
      background: 'transparent',
      transitionProperty: 'color, background-color',
      transitionTimingFunction: ({ ease }) => ease.inOutCubic,
      transitionDuration: '200ms',
      ':hover': {
        color: 'antiMode',
        background: 'mode',
      },
    })}
    {...other}
  >
    <span>{label}</span>
    {children}
  </Text>
);

export const Arrow = ({ label, ...other }) => (
  <Text
    css={css({
      ...flex,
      height: 'auto',
      color: 'mode',
      '&:hover': circleIcon.hoverStyles,
    })}
    {...other}
  >
    <span>{label}</span>
    <CircleIcon type="arrow" isSmall />
  </Text>
);

export const Play = ({ label, ...other }) => (
  <Text
    variant="caps"
    css={css({
      ...flex,
      '> * + *': {
        ml: other.flipped ? 0 : 2,
        mr: other.flipped ? 2 : 0,
      },
      '&:hover': circleIcon.hoverStyles,
    })}
    {...other}
  >
    <CircleIcon
      type="play"
      isSmall
      css={css({
        order: other.flipped ? 1 : '',
      })}
    />
    <span>{label}</span>
  </Text>
);

export const AppStore = (props) => (
  <div {...props} css={css({ ...fill, ...hover, p: 0, height: 'auto' })}>
    <AppStoreIcon css={css({ height: '3.5rem', mb: '-0.1rem' })} />
  </div>
);

export const Apple = (props) => (
  <Primary {...props}>
    <AppleLogo
      style={{ width: '1.2rem', height: '1.2rem', marginTop: '-0.2rem' }}
    />
  </Primary>
);

export const Windows = (props) => (
  <Secondary {...props}>
    <WindowsLogo style={{ width: '1.2rem', height: '1.2rem' }} />
  </Secondary>
);

export const Caps = ({ label, ...other }) => (
  <Text
    variant="caps"
    css={css({
      transition: 'color 0.2s',
      ':hover': {
        color: 'modeAlpha.5',
      },
    })}
    {...other}
  >
    {label}
  </Text>
);

export const C2C = ({ label, ...other }) => (
  <Text
    variant="caps"
    css={css({
      color: ({ mode }) => (mode === 'dark' ? 'offWhite' : 'black'),
      position: 'relative',
      background: 'c2cGradient',
      py: '0.75rem',
      px: '1rem',
      borderRadius: 1,
      zIndex: 1,
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        borderRadius: 'inherit',
        border: 'solid 2px transparent',
        backgroundColor: ({ mode }) => (mode === 'dark' ? 'black' : 'white'),
        backgroundClip: 'padding-box',
        transition: 'background-color 200ms ease-out',
      },
      '&:hover::before': {
        backgroundColor: ({ mode }) =>
          mode === 'dark' ? 'blackAlpha.8' : 'whiteAlpha.8',
      },
    })}
    {...other}
  >
    {label}
  </Text>
);

export const Underline = ({ label, ...other }) => (
  <Text
    variant="caps"
    css={css({
      transition: 'opacity 0.2s',
      textDecoration: 'underline',
      textUnderlineOffset: '0.3em',
      ':hover': {
        opacity: 0.5,
      },
    })}
    {...other}
  >
    {label}
  </Text>
);

export const C2CPlay = () => (
  <Box
    alignItems="center"
    justifyContent="center"
    css={css({
      size: '100%',
      transform: 'scale3d(1,1,1)',
      'svg:first-child': {
        transition: 'transform 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'rotateZ(0deg) scale3d(1, 1, 1)',
      },
      ':hover': {
        'svg:first-child': {
          transform: 'rotateZ(45deg) scale3d(1.2, 1.2, 1.2)',
        },
      },
    })}
  >
    <Layers
      css={css({
        size: ['5rem', null, '6rem'],
      })}
    >
      <C2CPlayCircle />
      <C2CPlayIcon />
    </Layers>
  </Box>
);

export const C2CPlaySmall = ({ label }) => (
  <Text
    variant="aktivLarge"
    color="offWhite"
    css={css({
      ...flex,
      transform: 'scale3d(1,1,1)',
      'svg:first-child': {
        transition: 'transform 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'rotateZ(0deg) scale3d(1, 1, 1)',
      },
      ':hover': {
        'svg:first-child': {
          transform: 'rotateZ(45deg) scale3d(1.2, 1.2, 1.2)',
        },
      },
    })}
  >
    <Layers
      css={css({
        size: '2rem',
      })}
    >
      <C2CPlayCircle />
      <C2CPlayIcon />
    </Layers>
    <span>{label}</span>
  </Text>
);
